import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import moment from 'moment';
import numeral from 'numeral';
import cn from 'classnames';
import ReactPlayer from 'react-player/file'
import { ResponsiveContainer, ComposedChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip, PieChart, Pie, Cell } from 'recharts';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import Loading from 'components/Loading';
import Footer from 'components/Footer';
import ArrowIcon from 'components/ArrowIcon';
import { loadAccount, incrementUserLogins } from 'actions/accountActions';
import { loadFinancialPriorities, loadHistoricalData, loadAssetsLiabilities } from 'actions/dataActions';
import { loadBudgetPlan, loadBudgetPlanMonth, loadBudgetPlanReview } from 'actions/budgetPlanActions';
import { loadRetirementPlanner, loadRPGoals } from 'actions/dataActions';
import { loadFinancialFact } from 'actions/miscActions';
import { formatData, accountNeedsUpdateWithin2Days, accountPastDueUpdate } from 'views/AssetsLiabilities/utils';
import { calculateTotal, calculateTotalPlanMonths, calculateReviewPacing } from 'views/BudgetPlanner/utils';
import { savingsRatePieLabel, scale, fieldNeedsUpdating } from 'views/RetirementPlanner/utils';
import { DATE_FORMAT, DATE_FORMAT_SHORT, NUM_FORMAT_SHORT, THIS_YEAR } from 'constants/config';
import { NET_WORTH, COLORS, DAY_FORMAT } from 'constants/assetsLiabilities';
import { EMPTY_ROWS } from 'constants/budgetPlanner';
import { DEFAULT_FINANCIAL_FACT, STROKES } from 'constants/financialPriorities';
import { GOAL_TYPES, DASHBOARD_RP_FIELDS } from 'constants/retirementPlanner';
import { tierIcons } from 'images/icons';
import OnTrackIcon from 'images/icons/on-track.svg';
import OffTrackIcon from 'images/icons/off-track.svg';
import CloseIcon from 'images/icons/close.svg';
import RPArrowIcon from 'images/icons/arrow.svg';
import PlayCircleIcon from 'images/icons/play_circle.svg';
import AssetsLiabilitiesBG from 'images/backgrounds/assets-liabilities.jpg';
import BudgetPlannerBG from 'images/backgrounds/budget-planner.jpg';
import RetirementPlannerBG from 'images/backgrounds/retirement-planner.jpg';
import GettingStartedVideo from 'videos/getting_started.mp4';
import DialogStyles from 'styles/DialogStyles.js';
import './Dashboard.scss';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    historical: state.data.historical,
    budgetPlan: state.budgetPlan.plan,
    budgetPlanMonth: state.budgetPlan.planMonth,
    budgetPlanReview: state.budgetPlan.budgetPlanReview,
    financialFact: state.misc.financialFact,
    assets: state.data.assets,
    liabilities: state.data.liabilities,
    lastUpdated: state.data.lastUpdated,
    retirementPlanner: state.data.retirementPlanner,
    rpGoals: state.data.rpGoals,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialFact: (user) => {
        dispatch(loadFinancialFact(user));
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadHistoricalData: (userId, startDate, endDate) => {
        dispatch(loadHistoricalData(userId, startDate, endDate));
    },
    loadBudgetPlan: (user, createPlan) => {
        dispatch(loadBudgetPlan(user, createPlan));
    },
    loadBudgetPlanMonth: (user, planId, month) => {
        dispatch(loadBudgetPlanMonth(user, planId, month));
    },
    loadBudgetPlanReview: (user, planId) => {
        dispatch(loadBudgetPlanReview(user, planId));
    },
    incrementUserLogins: (user) => {
        dispatch(incrementUserLogins(user));
    },
    loadAssetsLiabilities: (user) => {
        dispatch(loadAssetsLiabilities(user));
    },
    loadRetirementPlanner: (user) => {
        dispatch(loadRetirementPlanner(user));
    },
    loadRPGoals: (user, rpId, year, accountIds) => {
        dispatch(loadRPGoals(user, rpId, year, accountIds));
    },
});

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: moment().subtract(2, 'months').startOf('month').startOf('day'),
            endDate: moment(),
            fpLoaded: false,
            fpCalled: false,
            alLoaded: false,
            alCalled: false,
            bpLoaded: false,
            bpCalled: false,
            bpmLoaded: false,
            bpmCalled: false,
            bprLoaded: false,
            bprCalled: false,
            rpLoaded: false,
            rpCalled: false,
            financialFact: null,
            ffCalled: false,
            historicalLoaded: false,
            historicalCalled: false,
            breakdown: 'monthly',
            bpPlanId: null,
            bpStart: null,
            bpEnd: null,
            bpMonth: null,
            income: [Object.assign({}, EMPTY_ROWS.income)],
            incomeTotal: 0,
            incomeReview: 'all',
            savings: [Object.assign({}, EMPTY_ROWS.savings)],
            savingsTotal: 0,
            savingsReview: 'all',
            spending: [Object.assign({}, EMPTY_ROWS.spending)],
            spendingTotal: 0,
            spendingReview: 'all',
            reviewTotals: null,
            firstTimeLogin: false,
            assets: null,
            liabilities: null,
            barHover: {},
            mobile: (window.outerWidth < 1440),
            retirementPlanner: null,
            rpGoals: null,
            gettingStartedOpen: false,
        };

        this.incomeRef = React.createRef();
        this.savingsRef = React.createRef();
        this.spendingRef = React.createRef();
        this.videoRef = React.createRef();

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let account = nextProps.account, state = {};

        if (account.loaded && account.user) {
            // Load financial priorities data //
            if (nextProps.financialPriorities && !this.state.fpLoaded) {
                state.fpLoaded = true;
            } else if (!this.state.fpCalled) {
                state.fpCalled = true;
                this.props.loadFinancialPriorities(account.user);
            }

            // Load Assets & Liabilities for recurring tasks //
            if ((nextProps.assets || nextProps.liabilities) && !this.state.alLoaded) {
                state.alLoaded = true;
                state.assets = nextProps.assets;
                state.liabilities = nextProps.liabilities;
            } else if (!this.state.alCalled) {
                state.alCalled = true;
                this.props.loadAssetsLiabilities(account.user);
            }

            // Load Budget Plan for recurring tasks and BP summary //
            if (nextProps.budgetPlan && !this.state.bpLoaded) {
                let plan = nextProps.budgetPlan;
                state.bpStart = plan.start_date;
                state.bpEnd = plan.end_date;
                state.income = plan.records?.filter(r => r.type === 'income');
                if (!state.income || state.income.length === 0) state.income = [Object.assign({}, EMPTY_ROWS.income)];
                state.incomeTotal = calculateTotal(state.income);
                state.savings = plan.records?.filter(r => r.type === 'savings');
                if (!state.savings || state.savings.length === 0) state.savings = [Object.assign({}, EMPTY_ROWS.savings)];
                state.savingsTotal = calculateTotal(state.savings);
                state.spending = plan.records?.filter(r => r.type === 'spending');
                if (!state.spending || state.spending.length === 0) state.spending = [Object.assign({}, EMPTY_ROWS.spending)];
                state.spendingTotal = calculateTotal(state.spending.filter(s => !s.savings_withdrawal));

                if (plan.id && !this.state.bpmCalled) {
                    state.bpmCalled = true;
                    this.props.loadBudgetPlanMonth(account.user, plan.id, moment().startOf('month').toISOString());
                } else {
                    state.bpmCalled = true;
                    state.bpmLoaded = true;
                }

                if (plan.id && !this.state.bprCalled) {
                    state.bprCalled = true;
                    this.props.loadBudgetPlanReview(account.user, plan.id);
                } else {
                    state.bprCalled = true;
                    state.bprLoaded = true;
                }

                state.bpLoaded = true;
            } else if (!this.state.bpCalled) {
                state.bpCalled = true;
                this.props.loadBudgetPlan(account.user, false);
            }

            // Load Budget Plan Month for recurring tasks and BP summary //
            if (nextProps.budgetPlanMonth && !this.state.bpmLoaded) {
                state.bpmLoaded = true;
            }

            // Load Budget Plan Review for recurring tasks and BP summary //
            if (nextProps.budgetPlanReview && !this.state.bprLoaded) {
                let review = nextProps.budgetPlanReview.records;

                state.reviewTotals = { all: [], income: [], incomeTotal: 0, savings: [], savingsTotal: 0, spending: [], spendingTotal: 0, total: 0 };

                review.forEach(r => {
                    // Transaction amount logic (same as below when on Update tab) //
                    let amount = Number(r.amount);
                    if (r.positive_negative === 'positive' && r.type === 'spending') {
                        amount = -Math.abs(amount);
                    } else if ((r.positive_negative === 'negative' || r.positive_negative === null) && r.type === 'spending') {
                        amount = Math.abs(amount);
                    }

                    // If we don't have a sum record for this BPR category id, create one //
                    if (!state.reviewTotals.all.find(rt => rt.id === r.id)) {
                        let temp = Object.assign({}, r);
                        temp.total = 0;
                        delete temp.month;
                        state.reviewTotals.all.push(temp);
                    }

                    // Populate review totals info for plan pacing //
                    const reviewTotalsAllIndex = state.reviewTotals.all.findIndex(rt => rt.id === r.id);
                    state.reviewTotals.all[reviewTotalsAllIndex].total += amount;
                    if (!r.savings_withdrawal) state.reviewTotals[r.type+'Total'] += amount;
                    state.reviewTotals.total += amount;
                });

                // Make sure to populate type arrays of review totals after all is said and done //
                ['income', 'savings', 'spending'].forEach(t => {
                    state.reviewTotals[t] = state.reviewTotals.all.filter(rt => rt.type === t);
                });

                state.bprLoaded = true;
            }

            // Load retirement planner data for recurring tasks and RP summary //
            if (nextProps.retirementPlanner && !this.state.rpLoaded) {
                state.rpLoaded = true;
                state.retirementPlanner = nextProps.retirementPlanner
                if (state.retirementPlanner.id) {
                    this.props.loadRPGoals(account.user, state.retirementPlanner.id, THIS_YEAR, '');
                }
            } else if (!this.state.rpCalled) {
                state.rpCalled = true;
                this.props.loadRetirementPlanner(account.user);
            }
            if (nextProps.rpGoals && this.state.rpLoaded) {
                state.rpGoals = nextProps.rpGoals;
            }

            // Load weekly financial fact //
            if (nextProps.financialFact && !this.state.financialFact) {
                state.financialFact = nextProps.financialFact;
            } else if (!this.state.ffCalled) {
                state.ffCalled = true;
                this.props.loadFinancialFact(account.user);
            }

            // Load historical A&L data for A&L summary //
            if (nextProps.historical && !this.state.historicalLoaded) {
                state.historicalLoaded = true;
            } else if (!this.state.historicalCalled) {
                state.historicalCalled = true;
                this.props.loadHistoricalData(account.user.id, this.state.startDate.format(DAY_FORMAT),  this.state.endDate.format(DAY_FORMAT));
            }
        }

        this.setState(state);
    }

    toggleDialog = (e, id) => {
        e.preventDefault();
        let state = {};
        state[id] = !this.state[id];
        this.setState(state);
    }

    handleVideoStart = (e) => {
        const self = this;

        if (document.fullscreenElement) {
            document.fullscreenElement.addEventListener("fullscreenchange", function() {
                self.setState({ gettingStartedOpen: false });
            }, false);

            document.fullscreenElement.addEventListener("webkitendfullscreen", function() {
                self.setState({ gettingStartedOpen: false });
            }, false);
        }
    }

    handleResize = () => {
        this.setState({ mobile: (window.outerWidth < 1440) });
    }

    tierNav = (e, tier) => {
        window.location.href = `/plan?t=${tier.id}`
    }

    categoryNav = (e, task) => {
        window.location.href = `/plan?task=${task.id}`;
    }

    taskNav = (e, task) => {
        window.location.href = (task.link) ? `/task?tid=${task.id}` : `/plan?task=${task.id}`;
    }

    accountsNav = () => {
        window.location.href = '/assetsLiabilities';
    }

    closeFirstTimeDialog = () => {
        this.setState({ firstTimeLogin: false });
        this.props.incrementUserLogins(this.props.account.user);
    }

    goto = (tool, url, month) => {
        if (tool === 'al') {
            window.location.href = '/assetsLiabilities';
        } else if (tool === 'bp') {
            window.location.href = '/budgetPlanner';
        } else if (tool === 'fpap') {
            window.location.href = '/plan';
        } else if (tool === 'fact') {
            window.open(url, '_blank');
        } else if (tool === 'rp') {
            window.location.href = '/retirement';
        }
    }

    chartMouseOut = () => {
        this.setState({ barHover: {} });
    };

    chartMouseMove = (opts) => {
        const payload = (opts.activePayload) ? opts.activePayload[0].payload : {};
        const barHover = { ...payload, index: opts.activeTooltipIndex };

        if (barHover.index !== this.state.barHover.index) {
            this.setState({ barHover: barHover });
        }
    };

    rpBadgeCount = (rp, goals) => {
        let combined = Object.assign({}, rp, goals);
        let fieldsToCheck = {};
        DASHBOARD_RP_FIELDS.forEach(k => fieldsToCheck[k] = combined[k]);

        // I hate it //
        fieldsToCheck.grossAnnualIncome = fieldsToCheck.gross_annual_household_income;
        delete fieldsToCheck.gross_annual_household_income;
        fieldsToCheck.annualExpenses = fieldsToCheck.annual_household_expenses;
        delete fieldsToCheck.annual_household_expenses;

        if (!rp.partner_first_name && !rp.partner_birthday && !rp.partner_goal_retirement_age) {
            delete fieldsToCheck.partner_first_name;
            delete fieldsToCheck.partner_birthday;
            delete fieldsToCheck.partner_goal_retirement_age;
        }

        return Object.keys(fieldsToCheck).reduce((acc, curr) => acc + (Number(fieldNeedsUpdating(curr, fieldsToCheck[curr], moment(rp.modified), goals)) || 0), 0)
    };

    render() {
        const { startDate, endDate, fpLoaded, alLoaded, breakdown, financialFact, assets, liabilities, barHover, mobile, lastUpdated, retirementPlanner } = this.state;
        const { bpStart, bpEnd, reviewTotals, bpLoaded, bpmLoaded, bprLoaded, rpLoaded, rpGoals, historicalLoaded, gettingStartedOpen } = this.state;
        const { account, financialPriorities, historical, budgetPlan, classes } = this.props;

        let financialPlanSummary = (<Loading />);
        if (fpLoaded && financialPriorities) {
            let overviewProgress = {};
            financialPriorities.tiers.forEach(tier => {
                const categoryIds = financialPriorities.categories.filter(c => c.tier_id === tier.id).map(c => c.id);
                const topicIds = financialPriorities.topics.filter(t => categoryIds.includes(t.category_id) && t.visible).map(t => t.id);
                const tasks = financialPriorities.tasks.filter(t => topicIds.includes(t.topic_id) && t.visible);
                overviewProgress[tier.id] = (tasks.length) ? (tasks.filter(t => t.status).length / tasks.length) : 0;
            });

            financialPlanSummary = (
                <div className="tiers">
                    {financialPriorities.tiers.map(tier => {
                        const percentage = overviewProgress[tier.id];
                        return (
                            <div className="tier" key={tier.id} onClick={(e) => this.tierNav(e, tier)}>
                                <div className="metadata">
                                    <img src={tierIcons[tier.name]} alt={`Tier icon for the ${tier.name} tier`} />
                                    <h2>{tier.name}</h2>
                                </div>
                                <div className="pie">
                                    <PieChart width={(mobile) ? 70 : 105} height={(mobile) ? 70 : 105} >
                                         <Pie
                                            dataKey="value"
                                            innerRadius={'90%'}
                                            outerRadius={'100%'}
                                            fill="#E6E6E6"
                                            data={[{ value: 100 }]} />
                                        <Pie
                                            dataKey="value"
                                            innerRadius={'85%'}
                                            outerRadius={'105%'}
                                            cornerRadius={40}
                                            startAngle={90}
                                            endAngle={((360 * percentage) + 90)}
                                            fill="#40B7B0"
                                            data={[{ value: Math.round(percentage * 100) }]} />
                                    </PieChart>
                                    <label>{`${Math.round(percentage*100)}%`}</label>
                                    <ArrowIcon stroke={STROKES.green} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        let nextSteps = (<Loading />), noFinancialPlan = false;
        if (fpLoaded && financialPriorities && financialPriorities.tasks.length > 0) {
            nextSteps = (
                <>
                    {financialPriorities.tasks.filter(t => !t.status && t.visible).map((task, i) => {
                        if (i < 3) {
                            return (
                                <div className="next-step" key={i} onClick={(e) => this.taskNav(e, task)}>
                                    <div className="faux-checkbox"></div>
                                    <div className="task">{task.name}</div>
                                    <ArrowIcon stroke={STROKES.white} />
                                </div>
                            );
                        }

                        return null;
                    })}

                    {financialPriorities.tasks.filter(t => !t.status && t.visible).length === 0 &&
                        <div className="next-step no-next-steps" onClick={(e) => this.tierNav(e, {id: 0})}>
                            <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                            <div className="task">Congratulations! You've completed the Financial Priorities action plan!</div>
                        </div>
                    }
                </>
            );
        } else if (fpLoaded && financialPriorities && financialPriorities.tasks.length === 0) {
            noFinancialPlan = true;
            nextSteps = (
                <button className="no-recurring-task" onClick={() => this.goto('fpap')}>Create Action Plan</button>
            );
        }

        let recurringAL = (<Loading />), noRecurringAL = false, alBadge = 0;
        if (alLoaded && (assets || liabilities)) {
            if (assets.length === 0 && liabilities.length === 0 &&
                !assets.filter(a => a.customFreq).length &&
                !liabilities.filter(l => l.customFreq).length) {
                recurringAL = (
                    <button className="no-recurring-task" onClick={() => this.goto('al')}>Add Account Update Rules</button>
                );
                noRecurringAL = true;
            } else {
                let status = { updateSoon : 0, pastDue: 0 };

                [...assets, ...liabilities].forEach(a => {
                    if (accountPastDueUpdate(a)) {
                        status.pastDue += 1;
                    } else if (accountNeedsUpdateWithin2Days(a)) {
                        status.updateSoon += 1;
                    }
                });

                if (status.updateSoon === 0 && status.pastDue === 0) {
                    recurringAL = (
                        <div className="next-step green" onClick={() => this.goto('al')}>
                            <Checkbox checked={true} readOnly />
                            <div className="task">Your Assets & Liabilities are up to date!</div>
                            <ArrowIcon stroke={STROKES.green} />
                        </div>
                    );
                } else {
                    let needsUpdate = [];
                    if (status.pastDue > 0) {
                        const acct = (status.pastDue === 1) ? 'account' : 'accounts';
                        const are = (status.pastDue === 1) ? 'is' : 'are';
                        needsUpdate.push(<strong className="past-due" key={status.pastDue}>{`${status.pastDue} ${acct}`}</strong>);
                        needsUpdate.push(` ${are} ready for updating.`);
                        needsUpdate.push(<br key={123} />);
                        alBadge = status.pastDue;
                    }
                    if (status.updateSoon > 0) {
                        const acct = (status.updateSoon === 1) ? 'account' : 'accounts';
                        const need = (status.updateSoon === 1) ? 'needs' : 'need';
                        const prefix = (status.pastDue > 0) ? ',' : '';
                        needsUpdate.push(<strong className="update-soon" key={status.updateSoon}>{`${status.updateSoon} ${acct}`}</strong>);
                        needsUpdate.push(`${prefix} ${need} updating soon.`);
                    }

                    let stroke = STROKES.white;
                    if (status.pastDue === 0 && status.updateSoon > 0) {
                        stroke = STROKES.orange;
                    }
                    if (status.pastDue > 0) {
                        stroke = STROKES.red;
                    }

                    recurringAL = (
                        <div className={cn('next-step', status.pastDue > 0 && 'red', status.pastDue === 0 && status.updateSoon > 0 && 'orange')}  onClick={() => this.goto('al')}>
                            <div className="faux-checkbox"></div>
                            <div className="task">{needsUpdate}</div>
                            <ArrowIcon stroke={stroke} />
                        </div>
                    );
                }
            }
        }

        let recurringBP = (<Loading />), noRecurringBP = false;
        if (bpLoaded && bpmLoaded && bprLoaded) {
            if (!budgetPlan?.id) {
                recurringBP = (
                    <button className="no-recurring-task" onClick={() => this.goto('bp')}>Add Budget Plan</button>
                );
                noRecurringBP = true;
            } else {
                const currentMonth = moment().month() + 1, bp = budgetPlan;

                if (moment().date() === 27) {
                    recurringBP = (
                        <div className="next-step orange" onClick={() => this.goto('bp', null, currentMonth)}>
                            <div className="faux-checkbox"></div>
                            <div className="task">{`3 items for ${moment().format('MMMM')} need updating soon in Budget Planner.`}</div>
                            <ArrowIcon stroke={STROKES.orange} />
                        </div>
                    );
                } else {
                    let needsUpdating = false;
                    // Save this for https://app.asana.com/0/1155702592012091/1208060229808972/f //
                    // let needsUpdating = false, i = cg.startMonth;
                    //
                    // // Don't say it needs updating if the plan hasn't started yet //
                    // if (i === cg.startMonth && currentMonth >= i) {
                    //     while (i !== currentMonth) {
                    //         /* eslint-disable no-loop-func */
                    //         const actualRecords = ca.filter(c => c.month === i);
                    //         /* eslint-enable no-loop-func */
                    //
                    //         const planRecords = [...cg.income, ...cg.savings, ...cg.spending];
                    //
                    //         if (actualRecords.length !== planRecords.length) {
                    //             let numMissing = {income: 0, savings: 0, spending: 0};
                    //             planRecords.forEach(pr => {
                    //                 if (actualRecords.filter(ar => ar.record_id === pr.id).length === 0) {
                    //                     numMissing[pr.type] += 1;
                    //                 }
                    //             });
                    //
                    //             let missingText = [], count = 0, suffix = '', action = 'is';
                    //             if (numMissing.income > 0) {
                    //                 missingText.push(`${numMissing.income} Income`);
                    //                 count += numMissing.income;
                    //             }
                    //             if (numMissing.savings > 0) {
                    //                 if (numMissing.income > 0) missingText.push(' & ');
                    //                 missingText.push(`${numMissing.savings} Saving`);
                    //                 count += numMissing.savings;
                    //             }
                    //             if (numMissing.spending > 0) {
                    //                 if (numMissing.income > 0 && numMissing.savings > 0) {
                    //                     missingText[1] = ', ';
                    //                     missingText.push(', & ');
                    //                 } else if (numMissing.income > 0 || numMissing.savings > 0) {
                    //                     missingText.push(' & ');
                    //                 }
                    //                 missingText.push(`${numMissing.spending} Spending`);
                    //                 count += numMissing.spending;
                    //             }
                    //             if (count > 1) {
                    //                 suffix = 's';
                    //                 action = 'are';
                    //             }
                    //
                    //             /* eslint-disable no-loop-func */
                    //             recurringCFG = (
                    //                 <div className="next-step red" onClick={() => this.goto('bp', null, i)}>
                    //                     <div className="faux-checkbox"></div>
                    //                     <div className="task">
                    //                         {`${missingText.join('')} item${suffix} for ${moment().month(i-1).format('MMMM')} ${action} ready to update.`}
                    //                     </div>
                    //                     <ArrowIcon stroke={STROKES.red} />
                    //                 </div>
                    //             );
                    //             /* eslint-enable no-loop-func */
                    //
                    //             needsUpdating = true;
                    //             break;
                    //         } else {
                    //             i++;
                    //             if (i === 13) {
                    //                 i = 1;
                    //             }
                    //         }
                    //     }
                    //}

                    if (!needsUpdating) {
                        recurringBP = (
                            <div className="next-step green"  onClick={() => this.goto('bp')}>
                                <Checkbox checked={true} readOnly />
                                <div className="task">Your Budget Plan is up to date!</div>
                                <ArrowIcon stroke={STROKES.green} />
                            </div>
                        );
                    }
                }
            }
        }

        let recurringRP = (<Loading />), noRecurringRP = false, rpBadge = 0;
        if (rpLoaded) {
            const daysUntilStartOfMonth = Math.abs(moment().diff(moment().add(1, 'months').startOf('month'), 'days'));

            rpBadge = (retirementPlanner && rpGoals) ? this.rpBadgeCount(retirementPlanner, rpGoals) : 0;

            if (!retirementPlanner.id) {
                recurringRP = (
                    <button className="no-recurring-task" onClick={() => this.goto('rp')}>Add Retirement Plan</button>
                );
                noRecurringRP = true;
            } else if ([1, 2].includes(daysUntilStartOfMonth)) {
                recurringRP = (
                    <div className="next-step orange" onClick={() => this.goto('rp')}>
                        <div className="faux-checkbox"></div>
                        <div className="task">{`Your Retirement Planner needs updating soon.`}</div>
                        <ArrowIcon stroke={STROKES.orange} />
                    </div>
                );
                noRecurringRP = false;
            } else if (retirementPlanner && rpGoals && rpBadge > 0) {
                let noun = 'item', verb = 'is';
                if (rpBadge > 1) {
                    noun = 'items';
                    verb = 'are';
                }
                recurringRP = (
                    <div className="next-step red" onClick={() => this.goto('rp')}>
                        <div className="faux-checkbox"></div>
                        <div className="task">{`${rpBadge} Retirement Planner ${noun} ${verb} ready for updating.`}</div>
                        <ArrowIcon stroke={STROKES.red} />
                    </div>
                );
                noRecurringRP = false;
            } else {
                recurringRP = (
                    <div className="next-step green"  onClick={() => this.goto('rp')}>
                        <Checkbox checked={true} readOnly />
                        <div className="task">Your Retirement Planner is up to date!</div>
                        <ArrowIcon stroke={STROKES.green} />
                    </div>
                );
                noRecurringRP = false;
            }
        }

        let financialFactSummary = (<Loading />), factUrl = null;
        if (financialFact != null && financialFact.fact) {
            financialFactSummary = (<p dangerouslySetInnerHTML={{__html: financialFact.fact}} />);
            if (financialFact.fact.includes('a href')) {
                factUrl = financialFact.fact.split('a href="')[1].split('" target="_blank"')[0];
            }
        } else if (financialFact != null && !financialFact.fact) {
            financialFactSummary = (<p>{DEFAULT_FINANCIAL_FACT}</p>);
        }

        let historicalSummary = (<Loading />);
        let hasHistoricalData = false;
        Object.keys(historical || {}).forEach(h => {
            if (historical[h].length > 0) {
                hasHistoricalData = true;
            }
        });
        if (historicalLoaded && historical && hasHistoricalData) {
            let formattedData = formatData(historical, startDate, endDate, breakdown, []);

            const legendData = (Object.keys(barHover).length) ? barHover : formattedData.data[formattedData.data.length-1];
            let legendPayload = [{ label: null, value: moment(legendData.date).format('MMMM YYYY'), type: 'date', color: 'white' }];
            Object.keys(formattedData.legend).forEach((l, i) => {
                legendPayload.push({label: l, value: numeral(legendData[l]).format(NUM_FORMAT_SHORT), type: 'circle', color: COLORS[l]});
            });
            legendPayload.push({label: 'Net Worth', value: numeral(legendData['Net Worth']).format(NUM_FORMAT_SHORT), type: 'square', color: '#66D8DF'});

            historicalSummary = (
                <div className="historical-chart">
                    {formattedData.data.length > 0 && <>
                        <ResponsiveContainer>
                            <ComposedChart data={formattedData.data} margin={{ top: 30, right: 30, bottom: 20, left: 50, }} onMouseMove={this.chartMouseMove} onMouseLeave={this.chartMouseOut}>
                                <Tooltip contentStyle={{display: 'none'}} cursor={{ stroke: '#F4F6F8', strokeWidth: `${(100 / formattedData.data.length) - 9}%` }} />
                                <CartesianGrid stroke="#E6E6E6" vertical={false} />
                                <XAxis dataKey="date" minTickGap={0} tickFormatter={t => moment(t, DATE_FORMAT_SHORT).format('MMMM')} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                <YAxis tickFormatter={t => numeral(t).format(NUM_FORMAT_SHORT)} tickCount={6} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                <Bar dataKey={NET_WORTH} fill="#B9F1F4" />
                                {Object.keys(formattedData.legend).map((l, i) =>
                                    <Line type="monotone"
                                        dataKey={l}
                                        key={i}
                                        stroke={COLORS[l]}
                                        strokeWidth={2}
                                        dot={{fill: COLORS[l], stroke: COLORS[l], r: 7}} />
                                )}
                            </ComposedChart>
                        </ResponsiveContainer>

                        <div className="historical-legend dashboard">
                            <ul>
                                {legendPayload.map((lp, i) => {
                                    return (
                                        <li key={i}>
                                            {lp.type !== 'date' && <div className={`icon ${lp.type}`} style={{ backgroundColor: lp.color }} />}
                                            {lp.label && <label style={{ color: lp.color }}>{lp.label}</label>} &nbsp;
                                            <span>{lp.value}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>}
                    {formattedData.data.length === 0 &&
                        <div className="no-data">
                            There is no historical data for the specified start and end dates. Please check your inputs and try again.
                        </div>
                    }
                </div>
            );
        } else if (historical && !hasHistoricalData) {
            historicalSummary = (
                <div className="section-default" style={{backgroundImage: `url(${AssetsLiabilitiesBG})`}}>
                    <button className="create" onClick={() => this.goto('al')}>Create</button>
                </div>
            );
        }

        let budgetPlanSummary = (<Loading />), end = null, noBP = false;
        if (bpLoaded && bprLoaded && budgetPlan?.id) {
            const start = moment(bpStart).startOf('month').startOf('day');
            end = moment(bpEnd).endOf('month');
            const totalPlanMonths = (start && end) ? calculateTotalPlanMonths(start, end) : 1;

            let reviewPacing;
            if (reviewTotals) {
                ({ reviewPacing } = calculateReviewPacing(start, end, totalPlanMonths, reviewTotals, this.state));
            }
            const rpWidth = {'income': this.incomeRef?.current?.offsetWidth, 'savings': this.savingsRef?.current?.offsetWidth, 'spending': this.spendingRef?.current?.offsetWidth};

            budgetPlanSummary = (
                <div className="budget-plan-summary">
                    {reviewPacing.map((rp, i) => {
                        return (
                            <div className="pacing-section" key={i}>
                                <h2>
                                    {(rp.id === 'spending') ? rp.name : `Net ${rp.name}`}
                                    <div className={cn('summary mobile-version', (rp.onTrack) ? 'on-track' : 'off-track')}>
                                        {rp.onTrack && <>
                                            <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                            <label>On Track</label>
                                        </>}
                                        {!rp.onTrack && <>
                                            <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                            <label>Off Track</label>
                                        </>}
                                    </div>
                                </h2>
                                <div className="bar">
                                    <div className={cn('pace', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 99 && 'full')}
                                        style={{width: Math.min(100, rp.pace)+'%'}} ref={this[rp.id+'Ref']}>
                                        <label className={cn('total', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 25 && 'inside')}
                                            style={{right: (rp.pace >= 25) ? '15px' : 'auto', paddingLeft: (rp.pace >= 25) ? '0px' : `${rpWidth[rp.id]+7}px`}}>
                                            {numeral(rp.total).format(NUM_FORMAT_SHORT)}
                                        </label>
                                    </div>
                                    <label className="bar-goal">{numeral(rp.barGoal).format(NUM_FORMAT_SHORT)}</label>
                                </div>

                                <div className={cn('summary', (rp.onTrack) ? 'on-track' : 'off-track')}>
                                    {rp.onTrack && <>
                                        <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                        <label>On Track</label>
                                    </>}
                                    {!rp.onTrack && <>
                                        <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                        <label>Off Track</label>
                                    </>}
                                </div>
                                <div className="achievement-goal">
                                    <label className={cn('achievement', (rp.onTrack) ? 'on-track' : 'off-track')}>
                                        {(rp.barGoal === 0 || Number.isNaN(rp.barGoal)) ? '0%' :
                                            ((rp.total / rp.barGoal) * 100).toFixed(1) + '%'} <span>{(rp.id === 'spending') ? 'of max' : 'achievement'}</span>
                                    </label>
                                    <label className="goal">{numeral(rp.barGoal).format(NUM_FORMAT_SHORT)} <span>{(rp.id === 'spending') ? 'max' : 'goal'}</span></label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else if (bpLoaded && bprLoaded && !budgetPlan?.id) {
            noBP = true;
            budgetPlanSummary = (
                <div className="section-default" style={{backgroundImage: `url(${BudgetPlannerBG})`}}>
                    <button className="create" onClick={() => this.goto('bp')}>Create</button>
                </div>
            );
        }

        let rpSummary = (<Loading />), rpEnd = null, noRP = false, rpHeaderSuffix = '';
        if (rpLoaded && retirementPlanner.id) {
            if (rpGoals?.ytd_income === null || rpGoals?.ytd_retirement === null) {
                rpSummary = (
                    <div className="section-default" style={{backgroundImage: `url(${RetirementPlannerBG})`}}>
                        <p>It's Time To Set New Annual Goals</p>
                        <button className="create" onClick={() => this.goto('rp')}>Set Goals</button>
                    </div>
                );
            } else if (rpGoals?.ytd_income && rpGoals?.ytd_retirement && rpGoals?.minimum && rpGoals?.target && rpGoals?.stretch) {
                rpEnd = moment(`${THIS_YEAR}-12-31`);
                rpHeaderSuffix = ': Annual Progress';

                const savingsRates = {
                    actual: (rpGoals?.ytd_income > 0) ? (rpGoals.ytd_retirement / rpGoals.ytd_income) * 100 : 0,
                    minimum: (retirementPlanner?.gross_annual_household_income) ? Math.round((rpGoals.minimum / retirementPlanner.gross_annual_household_income) * 100) : 0,
                    target: (retirementPlanner?.gross_annual_household_income) ? Math.round((rpGoals.target / retirementPlanner.gross_annual_household_income) * 100) : 0,
                    stretch: (retirementPlanner?.gross_annual_household_income) ? Math.round((rpGoals.stretch / retirementPlanner.gross_annual_household_income) * 100) : 0,
                };
                let pieData = [{ name: 'Minimum', label: savingsRates.minimum, value: (savingsRates.stretch) ? (savingsRates.minimum / savingsRates.stretch) * 100 : 0}];
                pieData.push({ name: 'Target', label: savingsRates.target, value: (savingsRates.stretch) ? ((savingsRates.target / savingsRates.stretch) * 100) - pieData[0].value : 0 });
                pieData.push({ name: 'Stretch', label: savingsRates.stretch, value: (savingsRates.stretch) ? 100 - ((savingsRates.target / savingsRates.stretch) * 100) : 0 });
                const arrowRotate = Math.min(scale(savingsRates.actual, 0, savingsRates.stretch, 0, 180), 180);
                const arrowTransform = (mobile) ? `rotate(${arrowRotate}deg) scale(0.95)` : `rotate(${arrowRotate}deg)`;
                const arrowLeft = (mobile) ? window.outerWidth/2 - 120 : null;
                const barWidth = Math.max(1.1 * rpGoals.stretch, rpGoals.ytd_retirement) || 1;
                const progress = (rpGoals.ytd_retirement / barWidth) * 100;
                const goalWidths = {
                    'minimum': (rpGoals.minimum / barWidth) * 100,
                    'target': (rpGoals.target / barWidth) * 100,
                    'stretch': (rpGoals.stretch / barWidth) * 100,
                };

                rpSummary = (
                    <div className="rp-summary">
                        <div className="header"><span>Contributions&nbsp;</span> vs. Goals</div>
                        <div className="contributions">
                            <div className="bar">
                                <div className="pace" style={{ width: `${Math.min(Math.max(progress, 1), 100)}%` }}>
                                    <div className={cn('progress-label', progress < 20 && 'adjust')}>
                                        {numeral(rpGoals.ytd_retirement).format(NUM_FORMAT_SHORT)}
                                    </div>
                                </div>
                            </div>

                            {GOAL_TYPES.filter(gt => !gt.legendOnly).map((gt, i) => {
                                return (
                                    <div className={cn('goal-line', gt.id, i % 2 === 0 && 'even')} key={i} style={{ left: `${goalWidths[gt.id]}%` }}>
                                        <span>{numeral(rpGoals[gt.id]).format(NUM_FORMAT_SHORT)}</span>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="header"><span>Savings Rate&nbsp;</span> vs. Goals</div>
                        <div className="savings">
                            <PieChart width={(mobile) ? window.outerWidth - 20 : 510} height={250} margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
                                <Pie
                                    dataKey="value"
                                    startAngle={180}
                                    endAngle={0}
                                    data={pieData}
                                    cx={'50%'}
                                    cy={'60%'}
                                    outerRadius={135}
                                    innerRadius={75}
                                    label={savingsRatePieLabel}
                                    labelLine={false}
                                    isAnimationActive={false}
                                >
                                    {pieData.map((entry, i) => <Cell key={i} fill={GOAL_TYPES[i].fill} />)}
                                </Pie>
                            </PieChart>

                            <label className="actual">{`${savingsRates.actual.toFixed(1)}%`}</label>
                            <img src={RPArrowIcon} className="arrow" alt="Green arrow" style={{ transform: arrowTransform, left: arrowLeft }} />
                        </div>
                        <div className="legend">
                            {GOAL_TYPES.map((gt, i) => {
                                return (
                                    <div className="legend-item" key={i}>
                                        <div className={cn('circle', gt.id)} />
                                        <label className={gt.id}>{gt.dashboardLabel}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
        } else if (rpLoaded && !retirementPlanner.id) {
            noRP = true;
            rpSummary = (
                <div className="section-default" style={{backgroundImage: `url(${RetirementPlannerBG})`}}>
                    <button className="create" onClick={() => this.goto('rp')}>Create</button>
                </div>
            );
        }

        return (
            <div className="wrapper">
                <NavBarLoggedIn account={account} fp={financialPriorities} alBadge={alBadge} rp={rpBadge} />

                <div className="main-bg" />

                <div className="main dashboard">
                    <section className="action-plan">
                        <h1>Action Plan</h1>
                        <a href="/plan"><h2 className="sub">Financial Priorities</h2></a>
                        <h3>{financialPriorities?.description}</h3>

                        <div className="help" onClick={(e) => this.toggleDialog(e, 'gettingStartedOpen')}>
                            <span>Getting Started with MoneySwell</span>
                            <img src={PlayCircleIcon} alt="Play video icon, right-pointing triangle in a circle, green" />
                        </div>

                        {financialPlanSummary}
                    </section>

                    <section className="your-agenda">
                        <h1>Your Agenda</h1>
                        <h2>Recurring Tasks</h2>
                        <div className={cn('next-steps', noRecurringAL && noRecurringBP && noRecurringRP && 'tasks-empty-state')}>
                            {recurringAL}
                            {recurringBP}
                            {recurringRP}
                        </div>
                        <h2>Action Plan: <span>Financial Priorities</span></h2>
                        <div className={cn('next-steps', noFinancialPlan && 'plan-empty-state')}>
                            {nextSteps}
                        </div>
                    </section>

                    <section className="financial-fact">
                        <div className="fact">
                            <h1>Weekly Fact</h1>
                            <p>{moment().format(DATE_FORMAT)}</p>
                            {financialFactSummary}
                            {factUrl && <ArrowIcon stroke={STROKES.white} onClick={(e) => this.goto('fact', factUrl)} />}
                        </div>
                        <div className="background" />
                    </section>

                    <section className={cn('assets-liabilities', !hasHistoricalData && 'no-data')}>
                        <a href="/assetsLiabilities"><h1>Assets & Liabilities</h1></a>
                        {lastUpdated !== '' && hasHistoricalData && <h2 className="sub">Last Updated {moment(lastUpdated).format(DATE_FORMAT)}</h2>}
                        {!hasHistoricalData && <h2 className="sub heavy">Track your net worth & account growth</h2>}
                        {historicalSummary}
                    </section>

                    <section className={cn('budget-planner', !hasHistoricalData && 'no-data', noBP && 'no-plan')}>
                        <a href="/budgetPlanner"><h1>Budget Planner</h1></a>
                        <h2 className="sub">Goal End Date: <span>{(end && bpEnd !== null) ? end.format(DATE_FORMAT) : 'n/a'}</span></h2>
                        {budgetPlanSummary}
                        {end && <a href="/budgetPlanner" className="update">Update <ArrowIcon stroke={STROKES.green} /></a>}
                    </section>

                    <section className={cn('retirement-planner', noRP && 'no-plan')}>
                        <a href="/retirement"><h1>Retirement Planner{rpHeaderSuffix}</h1></a>
                        <h2 className="sub">Goal End Date: <span>{(rpEnd) ? rpEnd.format(DATE_FORMAT) : 'n/a'}</span></h2>
                        {rpSummary}
                        {rpEnd && <a href="/retirement" className="update">Update <ArrowIcon stroke={STROKES.green} /></a>}
                    </section>

                    <section className="financial-fact mobile-version">
                        <div className="fact">
                            <h1>Weekly Fact</h1>
                            <p>{moment().format(DATE_FORMAT)}</p>
                            {financialFactSummary}
                            {factUrl && <ArrowIcon stroke={STROKES.white} onClick={(e) => this.goto('fact', factUrl)} />}
                        </div>
                        <div className="background" />
                    </section>

                    {mobile && gettingStartedOpen &&
                        <div className="video-mobile">
                            <ReactPlayer ref={this.videoRef} url={GettingStartedVideo} playing={gettingStartedOpen} controls={true} width={'100%'} onStart={this.handleVideoStart} />
                        </div>
                    }
                </div>

                <Dialog onClose={this.closeFirstTimeDialog} open={this.state.firstTimeLogin} className={classes.Dialog}>
                    <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.closeFirstTimeDialog} />
                    <p>Welcome to MoneySwell! Get started by clicking the Action Plan link in the
                    navigation above or by creating one of the dashboards below.</p>
                    <button className="primary" onClick={this.closeFirstTimeDialog}>Ok</button>
                </Dialog>

                {!mobile &&
                    <Dialog onClose={(e) => this.toggleDialog(e, 'gettingStartedOpen')} open={gettingStartedOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'gettingStartedOpen')} />
                        <ReactPlayer url={GettingStartedVideo} playing={gettingStartedOpen} controls={true} width={1100} height={600} />
                    </Dialog>
                }

                <Footer />
            </div>
        );
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
