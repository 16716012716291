import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { loadAccount, deleteAccount, reactivateAccount } from 'actions/accountActions';
import { submitPayment, changePlanType, applyDiscountCode } from 'actions/chargebeeActions';
import { DATE_FORMAT, DATE_FORMAT_INTL, DELETED, MONTHLY_AMOUNT, ANNUAL_AMOUNT, TRIAL_STATUS } from 'constants/config';
import CloseIcon from 'images/icons/close.svg';
import DialogStyles from 'styles/DialogStyles.js';
import './SubscriptionSettings.scss';

const mapStateToProps = state => ({
    account: state.account,
    error: state.chargebee.error,
    success: state.chargebee.success,
    discount: state.chargebee.discount,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    submitPayment: (payment) => {
        dispatch(submitPayment(payment));
    },
    deleteAccount: (user, deletionDate) => {
        dispatch(deleteAccount(user, deletionDate));
    },
    reactivateAccount: (user) => {
        dispatch(reactivateAccount(user));
    },
    changePlanType: (user) => {
        dispatch(changePlanType(user));
    },
    applyDiscountCode: (discountCode) => {
        dispatch(applyDiscountCode(discountCode));
    },
});

class SubscriptionSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            planType: null,
            cardNumber: '',
            expiration: '',
            cvv: '',
            discountCode: '',
            discountDetails: null,
            paid: false,
            cancelText: '',
            deleteText: '',
            accountCancelled: false,
            signupDialogOpen: false,
            changePlanDialogOpen: false,
            edit: {},
        };
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = {};
        let user = nextProps?.account?.user;

        if (user) {
            state.loaded = true;

            if (this.state.planType === null) {
                state.planType = user.currentSubscription?.billing_period_unit;
            }

            if (user?.card) {
                state.paid = true;
                state.cardNumber = `**** **** **** ${user.card.last4}`;
                state.expiration = `${user.card.expiry_month}/${user.card.expiry_year}`;
                state.cvv = '***';
            }

            if (user.status === DELETED || nextProps?.edit?.success?.user?.status === DELETED) {
                state.accountCancelled = true;
            }
        }

        if (nextProps?.success?.signup) {
            state.signupDialogOpen = true;
        } else if (!nextProps?.success?.signup && nextProps?.success?.editPayment) {
            const paymentInfo = nextProps.success.paymentInfo;
            state.cardNumber = `**** **** **** ${paymentInfo.cardNumber.substring(12)}`;
            state.expiration = `${paymentInfo.expiration}`;
        }

        if (nextProps?.account?.edit) {
            state.edit = nextProps.account.edit;
        }

        if (nextProps?.success || nextProps?.error) {
            state.edit = {
                success: nextProps.success,
                error: nextProps.error,
            };
        }

        state.discountDetails = nextProps.discount;

        this.setState(state);
    }

    updateState = (e, key, value) => {
        if (key === 'planType' && value !== this.state.planType && this.props.account?.user?.card) {
           this.setState({ changePlanDialogOpen: !this.state.changePlanDialogOpen });
        } else {
            let state = {};
            state[key] = (value) ? value : e.target.value;
            this.setState(state);
        }
    }

    applyDiscountCode = (e) => {
        e.preventDefault();

        if (this.state.discountCode === '') {
            alert('Discount Code field cannot be empty.');
            return false;
        }

        this.props.applyDiscountCode(this.state.discountCode);

        return false;
    }

    submitPaymentForm = (e) => {
        e.preventDefault();

        if (this.state.cardNumber === '') {
            alert('Card Number cannot be empty.');
            return false;
        }

        if (this.state.expiration === '') {
            alert('Expiration cannot be empty.');
            return false;
        }

        if (!this.state.expiration.match(/\d\d\/\d\d\d\d/)) {
            alert('Expiration needs to be in the form MM/YYYY.');
            return false;
        }

        this.props.submitPayment({
            userId: this.props.account.user.id,
            customerId: this.props.account.user.customer.id,
            subscription: this.props.account.user.currentSubscription,
            isEdit: !!this.props.account.user.card,
            planType: this.state.planType,
            coupon: this.state.discountDetails,
            cardNumber: this.state.cardNumber,
            expiration: this.state.expiration,
            cvv: this.state.cvv,
        });

        return false;
    }

    changePayment = (e) => {
        e.preventDefault();

        this.setState({
            cardNumber: '',
            expiration: '',
            cvv: '',
            paid: false,
        })

        return false;
    }

    cancelChangePayment = (e) => {
        e.preventDefault();

        if (!this.props.account?.user?.card) {
            window.location.href = '/account';
            return false;
        }

        this.setState({
            cardNumber: `**** **** **** ${this.props.account?.user.card.last4}`,
            expiration: `${this.props.account?.user.card.expiry_month}/${this.props.account?.user.card.expiry_year}`,
            cvv: '***',
            paid: true,
        })

        return false;
    }

    submitCancelAccount = (e) => {
        e.preventDefault();

        if (this.state.cancelText !== 'cancel') {
            alert('Please enter "cancel" in the text field');
            return false;
        }

        if (window.confirm('Are you sure you want to cancel your account?')) {
            const user = this.props.account.user;
            const endDate = user.currentSubscription?.next_billing_at || user.currentSubscription?.trial_end;
            const deletionDate = (endDate) ? moment(endDate * 1000).format(DATE_FORMAT_INTL) : moment().add(30, 'days').format(DATE_FORMAT_INTL);

            this.props.deleteAccount(user, deletionDate);
        }

        return false;
    }

    submitDeleteAccount = (e) => {
        e.preventDefault();

        if (this.state.deleteText !== 'deletenow') {
            alert('Please enter "deletenow" in the text field');
            return false;
        }

        if (window.confirm('Are you sure you want to delete your account? This cannot be undone!')) {
            const user = this.props.account.user;

            this.props.deleteAccount(user);
        }

        return false;
    }

    reactivateAccount = (e) => {
        e.preventDefault();
        this.props.reactivateAccount(this.props.account.user);
        return false;
    }

    changePlanType = () => {
        const newPlanType = (this.state.planType === 'month') ? 'year' : 'month';

        this.props.changePlanType(this.props.account.user);

        this.setState({
            planType: newPlanType,
            changePlanDialogOpen: false,
        });
    }

    toggleSignupDialog = () => {
        this.setState({ signupDialogOpen: !this.state.signupDialogOpen });
    }

    toggleChangePlanDialog = () => {
        this.setState({ changePlanDialogOpen: !this.state.changePlanDialogOpen });
    }

    goto = (page) => {
        window.location.href = `/${page}`;
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    render() {
        const { loaded, planType, cardNumber, expiration, cvv, discountCode, discountDetails, paid } = this.state;
        const { cancelText, deleteText, accountCancelled, signupDialogOpen, changePlanDialogOpen, edit } = this.state;
        const { account, classes } = this.props;

        if (!loaded) {
            return (<Loading />);
        } else {
            const basis = (planType === 'month') ? 'a monthly' : 'an annual';
            const isEditPaymentDetails = (!paid && account?.user?.card);
            const trialUser = (account?.user?.currentSubscription?.status === TRIAL_STATUS && !account?.user?.card);
            const cta = (account?.user?.card) ? 'Change Payment Details' : 'Confirm & Sign Up';

            let amount = (planType === 'month') ? MONTHLY_AMOUNT : ANNUAL_AMOUNT;
            if (discountDetails?.discount_type === 'fixed_amount' && amount === ANNUAL_AMOUNT) {
                amount = ANNUAL_AMOUNT - (discountDetails.discount_amount / 100);
            } else if (discountDetails?.discount_type === 'percentage' && amount === ANNUAL_AMOUNT) {
                amount = ANNUAL_AMOUNT - (ANNUAL_AMOUNT * (discountDetails.discount_percentage / 100));
            }
            amount = Math.max(amount, 0);

            let confirmText = '', chargeDate = moment(account?.user?.currentSubscription?.next_billing_at * 1000).format(DATE_FORMAT);
            if (trialUser && !discountDetails) {
                confirmText = (<p>Your account will be charged <b>${amount.toFixed(2)}</b> on {chargeDate}, recurring on {basis} basis until you cancel your account.</p>);
            } else if (trialUser && discountDetails) {
                confirmText = (<p>With your discount code your account will be charged <b>${amount.toFixed(2)}</b> on {chargeDate}, and <b>${ANNUAL_AMOUNT.toFixed(2)}</b> at your next renewal on {basis} basis until you cancel your account.</p>);
            }

            let alert = null;
            if (edit && edit.success) {
                alert = { severity: 'success', msg: edit.success.msg };
            } else if (edit && edit.error) {
                alert = { severity: 'error', msg: edit.error.body.msg };
            }


            if (accountCancelled) {
                return (
                    <div className="wrapper">
                        <NavBarLoggedIn account={account} />

                        <div className="main subscription-settings">
                            <h1>Account Subscription Settings</h1>

                            <h2 className="ss-alt">Account Currently Canceled</h2>
                            <p>Your account has been canceled and you will incur no additional charges. The last active day
                            for your account is <b>{moment(account?.user?.deletion_date).format(DATE_FORMAT)}</b>. 30 days
                            following this date, your account and data will be permanently deleted.</p>
                            <a href="noop" className="reactivate-account" onClick={this.reactivateAccount}><b>Click here to reactivate your account.</b></a>

                            <h2 className="ss-alt">Immediate Permanent Deletion</h2>
                            <p>If you would like to permanently delete your account and all associated data immediately, type
                            "deletenow" in the box below. Please note the following:</p>
                            <ul>
                                <li>This action will immediately delete your account and all associated data.</li>
                                <li>This <b>CANNOT</b> be undone.</li>
                                <li>You will lose access to MoneySwell <b>IMMEDIATELY</b> and forfeit any remaining active time available on your account.</li>
                                <li>If you have paid for a monthly or annual membership, you will <b>NOT</b> receive a credit for your forfeited active time.</li>
                            </ul>
                            <p>Are you sure you want to delete your account?</p>
                            <form name="delete-account" className="delete-account" onSubmit={this.submitDeleteAccount}>
                                <fieldset>
                                    <label></label>
                                    <input type="text"
                                        id="delete"
                                        placeholder="Type 'deletenow'"
                                        value={deleteText}
                                        onChange={(e) => this.updateState(e, 'deleteText')} />
                                </fieldset>
                                <input type="submit" className="cancel" value="Permanently Delete Account" />
                            </form>
                        </div>

                        {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                        <Footer />
                    </div>
                );
            } else {
                return (
                    <div className="wrapper">
                        <NavBarLoggedIn account={account} />

                        <div className="main subscription-settings">
                            <h1>Account Subscription Settings</h1>
                            <a href="/account" className="back-link">{'<< Back to My Account'}</a>

                            <h2>Select Account Type</h2>
                            <div className="plans">
                                <div className={cn('plan', planType === 'month' && 'selected')} onClick={(e) => this.updateState(e, 'planType', 'month')}>
                                    <h3>Monthly</h3>
                                    <label>$9/month</label>
                                    <p>Charged once monthly. No commitment.</p>
                                </div>
                                <div className={cn('plan', planType === 'year' && 'selected')} onClick={(e) => this.updateState(e, 'planType', 'year')}>
                                    <h3>Annual</h3>
                                    <label>$95/year</label>
                                    <p>Charged once annually. No commitment.</p>
                                </div>
                            </div>

                            <h2>Payment Details</h2>
                            <p>Payment details below will be used for future subscription charges.</p>
                            <form name="payment" onSubmit={this.submitPaymentForm}>
                                <fieldset>
                                    <label htmlFor="cardNumber">Card Number</label>
                                    <input type="text"
                                        id="cardNumber"
                                        className={cn(paid && 'disabled')}
                                        placeholder="0123 4567 8910 1112"
                                        value={cardNumber}
                                        onChange={(e) => this.updateState(e, 'cardNumber')} />
                                </fieldset>

                                <fieldset className="half">
                                    <label htmlFor="expiration">Expiration</label>
                                    <input type="text"
                                        id="expiration"
                                        className={cn(paid && 'disabled')}
                                        placeholder="MM/YYYY"
                                        maxLength={7}
                                        value={expiration}
                                        onChange={(e) => this.updateState(e, 'expiration')} />
                                </fieldset>

                                <fieldset className="half last">
                                    <label htmlFor="cvv">CVV</label>
                                    <input type="text"
                                        id="cvv"
                                        className={cn(paid && 'disabled')}
                                        placeholder="123"
                                        value={cvv}
                                        onChange={(e) => this.updateState(e, 'cvv')} />
                                </fieldset>

                                {!paid && planType === 'year' &&
                                    <div className="discount-code">
                                        <h2>Discount Code</h2>
                                        <p>If you have a discount code, enter it below.</p>
                                        <input type="text" placeholder="Enter Discount Code" value={discountCode} onChange={(e) => this.updateState(e, 'discountCode')} />
                                        <a href="noop" className="apply" onClick={this.applyDiscountCode}>Apply</a>
                                    </div>
                                }

                                {!paid && !isEditPaymentDetails && <h2>Confirm & Sign Up</h2>}
                                {!paid && !isEditPaymentDetails && <>{confirmText}</>}
                                {!paid &&
                                    <div className="actions">
                                        <button className="secondary" onClick={this.cancelChangePayment}>Cancel</button>
                                        <input type="submit" className="primary" value={cta} />
                                    </div>
                                }
                                {paid && <a href="noop" className="change-payment" onClick={this.changePayment}>Change Payment Details</a>}
                            </form>

                            <hr />

                            <form name="cancel-account" className="cancel-account" onSubmit={this.submitCancelAccount}>
                                <h2>Cancel Account</h2>

                                <span><b>WARNING:</b> Cancelled accounts remain active through the end of the current billing cycle
                                or free trial period (if applicable). You will incur no additional charges. All your account data
                                will be marked for <b>permanent deletion</b> 30 days after the last active date of your account.</span>

                                <fieldset>
                                    <label></label>
                                    <input type="text"
                                        id="cancel"
                                        placeholder="Type 'cancel'"
                                        value={cancelText}
                                        onChange={(e) => this.updateState(e, 'cancelText')} />
                                </fieldset>

                                <input type="submit" className="cancel" value="Cancel Account" />
                            </form>
                        </div>

                        <Dialog open={signupDialogOpen} className={classes.Dialog}>
                            <h1>Thank you for signing up!</h1>
                            <p>You're all signed up for MoneySwell! We're thrilled to have you as a customer and to help you achieve better financial health.</p>

                            <button className="primary" onClick={(e) => this.goto('dashboard')}>Go to My Dashboard</button>
                            <br />
                            <button className="primary" onClick={(e) => this.goto('account')}>Back to My Account</button>
                        </Dialog>

                        <Dialog onClose={this.toggleChangePlanDialog} open={changePlanDialogOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleChangePlanDialog} />

                            <h1>Change Plan Type?</h1>
                            <p>Would you like to change your plan?</p>

                            <div className="actions">
                                <button className="secondary" onClick={this.toggleChangePlanDialog}>Keep Current Plan</button>
                                <button className="primary" onClick={this.changePlanType}>Change Plan Type</button>
                            </div>
                        </Dialog>

                        {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                        <Footer />
                    </div>
                );
            }
        }
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionSettings);
