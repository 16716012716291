import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import moment from 'moment';
import numeral from 'numeral';
import cn from 'classnames';
import { findIndex } from 'lodash';
import { ResponsiveContainer, ComposedChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import Dialog from '@material-ui/core/Dialog';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import TooltipMUI from '@material-ui/core/Tooltip';
import { PlaidLink } from 'react-plaid-link';
import Loading from 'components/Loading';
import NavBar from 'components/NavBar';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import IconButton from 'components/IconButton';
import BackLink from 'components/BackLink';
import CountUpAnimation from 'components/CountUpAnimation';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, addAccount, editAccount, deleteAccount, loadAssetsLiabilities, saveHistoricalRecord, loadHistoricalData } from 'actions/dataActions';
import { getLinkToken, getAccessTokenAndCreateAccounts, syncLinkedAccounts } from 'actions/plaidActions';
import { formatData, accountNeedsUpdateWithin2Days, accountPastDueUpdate } from './utils';
import { ASSETS, LIABILITIES, BREAKDOWNS, NET_WORTH, DAY_FORMAT, COLORS, WEEKDAYS, MAGIC_NUMERATOR } from 'constants/assetsLiabilities';
import { DATE_FORMAT, DATE_FORMAT_SHORT, DATE_FORMAT_US, NUM_FORMAT, ZERO_NUM, NUM_FORMAT_SHORT, ITEM_LOGIN_REQUIRED } from 'constants/config';
import AssetsIcon from 'images/icons/assets.svg';
import LiabilitiesIcon from 'images/icons/liabilities.svg';
import RefreshIcon from 'images/icons/refresh.svg';
import ErrorIcon from 'images/icons/error.svg';
import AddIcon from 'images/icons/add.svg';
import CloseIcon from 'images/icons/close.svg';
import DialogStyles from 'styles/DialogStyles.js';
import "react-datepicker/dist/react-datepicker.css";
import './AssetsLiabilities.scss';

const EMPTY_ACCOUNT = { name: '', type: '', value: '', customNum: 1, customFreq: 'weeks' };
const HEADER_FIELDS = ['Name', 'Current Value', 'Category', 'Linked/ Manual', 'Last Updated'];

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    assets: state.data.assets,
    liabilities: state.data.liabilities,
    newAccount: state.data.newAccount,
    editedAccount: state.data.editedAccount,
    linkToken: state.plaid.linkToken,
    historical: state.data.historical,
    lastUpdated: state.data.lastUpdated,
    success: state.data.success,
    error: state.data.error,
    sync: state.data.sync,
    preferences: state.data.preferences,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadAssetsLiabilities: (user) => {
        dispatch(loadAssetsLiabilities(user));
    },
    addAccount: (user, account) => {
        dispatch(addAccount(user, account));
    },
    editAccount: (user, account) => {
        dispatch(editAccount(user, account));
    },
    deleteAccount: (user, account) => {
        dispatch(deleteAccount(user, account));
    },
    getLinkToken: (user, accessToken) => {
        dispatch(getLinkToken(user, accessToken));
    },
    getAccessTokenAndCreateAccounts: (user, metadata) => {
        dispatch(getAccessTokenAndCreateAccounts(user, metadata));
    },
    syncLinkedAccounts: (user) => {
        dispatch(syncLinkedAccounts(user));
    },
    saveHistoricalRecord: (user, today, accounts) => {
        dispatch(saveHistoricalRecord(user, today, accounts));
    },
    loadHistoricalData: (userId, startDate, endDate, updatePrefs, breakdown) => {
        dispatch(loadHistoricalData(userId, startDate, endDate, updatePrefs, breakdown));
    },
});

class AssetsLiabilities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            dataLoaded: false,
            historicalLoaded: false,
            syncing: false,
            tab: 'Accounts',
            sort: 'Name',
            sortDir: 'asc',
            assets: [Object.assign({}, EMPTY_ACCOUNT)],
            liabilities: [Object.assign({}, EMPTY_ACCOUNT)],
            totals: {assets: 0, liabilities: 0, netWorth: 0},
            startDate: moment().subtract(11, 'months').startOf('month'),
            dirtyStartDate: null,
            endDate: moment(),
            dirtyEndDate: null,
            breakdown: 'monthly',
            dirtyBreakdown: null,
            configError: '',
            infoDialogOpen: false,
            addDialogOpen: false,
            addingAccountType: null,
            newAccount: Object.assign({}, EMPTY_ACCOUNT),
            editDialogOpen: false,
            editingAccount: null,
            edit: { success: false, error: false },
            excludeAccounts: [],
            syncResults: null,
            reminderInfoDialogOpen: false,
            selectAllassets: true,
            selectAllliabilities: true,
            editHistoricalDialogOpen: false,
            editHistoricalRemember: false,
            barHover: {},
            expandMobile: null,
            mobileLegend: window.innerWidth < 1440,
            mobileLegendOpen: false,
            mobileUpdateReminders: false,
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;

            if (!nextProps.financialPriorities && !nextProps.historical && !nextProps.assets && !nextProps.liabilities) {
                this.props.loadFinancialPriorities(account.user);
            }
        }

        if (nextProps.newAccount) {
            nextProps[nextProps.newAccount.category].push(nextProps.newAccount);
        } else if (nextProps?.editedAccount?.category) {
            nextProps[nextProps.editedAccount.category].forEach((a, i) => {
                if (a.id === nextProps.editedAccount.id) {
                    nextProps[nextProps.editedAccount.category][i] = Object.assign({}, nextProps.editedAccount);
                }
            });
        }

        if ((!this.state.dataLoaded || this.state.syncing) && (nextProps.assets || nextProps.liabilities)) {
            state.dataLoaded = true;
            state.assets = nextProps.assets.sort((a, b) => this.sortFunc(a, b, this.state.sort, this.state.sortDir));
            state.liabilities = nextProps.liabilities.sort((a, b) => this.sortFunc(a, b, this.state.sort, this.state.sortDir));
            state.totals = {
                assets: nextProps.assets.reduce((total, a) => total += numeral(a.value).value(), 0),
                liabilities: nextProps.liabilities.reduce((total, l) => total += numeral(l.value).value(), 0),
            };
            state.totals.netWorth = state.totals.assets - state.totals.liabilities;
            state.syncing = false;
            state.syncResults = nextProps.sync;
            if (state.assets.length === 0 && state.liabilities.length === 0) {
                if (!nextProps.linkToken) {
                    this.props.getLinkToken(account.user);
                }
            }
            setTimeout(() => {
                if (window.location.search.includes('t')) {
                    this.toggleView(null, window.location.search.split('=')[1]);
                }
            }, 300);
        } else {
            if (account.loaded && account.user && !nextProps.success && !nextProps.error && !nextProps.historical && !nextProps.linkToken && !nextProps.financialPriorities) {
                this.props.loadAssetsLiabilities(account.user);
            }
        }

        if (nextProps.historical) {
            state.historicalLoaded = true;

            if (nextProps.preferences && Object.keys(nextProps.preferences).length > 0) {
                state.startDate = (nextProps.preferences?.historical_start) ? moment(nextProps.preferences.historical_start) : moment().subtract(11, 'months').startOf('month');
                state.endDate = (nextProps.preferences?.historical_end) ? moment(nextProps.preferences.historical_end) : moment();
                state.breakdown = (nextProps.preferences?.historical_breakdown) ? nextProps.preferences.historical_breakdown : 'monthly';
            }
        }

        if (this.state.dataLoaded && nextProps.success && !nextProps.error) {
            state.assets = nextProps.assets.sort((a, b) => this.sortFunc(a, b, this.state.sort, this.state.sortDir));
            state.liabilities = nextProps.liabilities.sort((a, b) => this.sortFunc(a, b, this.state.sort, this.state.sortDir));
            state.totals = {
                assets: nextProps.assets.reduce((total, a) => total += numeral(a.value).value(), 0),
                liabilities: nextProps.liabilities.reduce((total, l) => total += numeral(l.value).value(), 0),
            };
            state.totals.netWorth = state.totals.assets - state.totals.liabilities;
        }

        if (nextProps.linkToken) {
            localStorage.setItem('msLinkToken', nextProps.linkToken);
        }

        state.edit = {
            success: nextProps.success,
            error: nextProps.error,
        };

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobileLegend: window.innerWidth < 1440 });
    }

    toggleAdd = () => {
        if ((!this.state.addDialogOpen && !this.props.linkToken) ||
            (!this.state.addDialogOpen && this.props.linkToken && this.state.editingAccount)) {
            this.props.getLinkToken(this.props.account.user);
        }
        this.setState({
            addDialogOpen: !this.state.addDialogOpen,
            addingAccountType: null,
            newAccount: Object.assign({}, EMPTY_ACCOUNT),
            mobileUpdateReminders: false,
            expandMobile: null,
        });
    }

    setAccountType = (e, type) => {
        e.preventDefault();
        this.setState({ addingAccountType: type });
    }

    onLinkSuccess = (token, metadata) => {
        if (!this.state.editingAccount) {
            this.props.getAccessTokenAndCreateAccounts(this.props.account.user, metadata);
        }
        this.setState({
            addDialogOpen: false,
            editDialogOpen: false,
            addingAccountType: null,
            newAccount: Object.assign({}, EMPTY_ACCOUNT),
            dataLoaded: (this.state.editingAccount) ? true : false,
            editingAccount: null,
            syncResults: null,
        });
    }

    onChangeAccount = (e, field, isEdit) => {
        const stateType = (isEdit) ? 'editingAccount' : 'newAccount';

        let val = null;
        if (e.target) {
             val = (field === 'value') ? numeral(e.target.value).value() : e.target.value;
             if (val === null && e.target.textContent) {
                 val = e.target.textContent;
             }
        } else {
            val = e;
        }

        let thisState = Object.assign({}, this.state);
        thisState[stateType][field] = val;
        let state = {};
        state[stateType] = thisState[stateType];
        this.setState(state);
    }

    toggleWeekday = (e, day, isEdit) => {
        const stateType = (isEdit) ? 'editingAccount' : 'newAccount';
        const account = Object.assign({}, this.state[stateType]);
        const index = account.weekdays?.indexOf(day.full);

        if (typeof(index) === 'undefined') {
            account.weekdays = [day.full];
        } else if (index === -1) {
            account.weekdays.push(day.full);
        } else {
            account.weekdays.splice(index, 1);
        }

        let state ={};
        state[stateType] = account;
        this.setState(state);
    }

    addAccount = () => {
        let acct = Object.assign({}, this.state.newAccount);
        acct.category = 'assets';
        if (LIABILITIES.filter(a => a.value === acct.type).length === 1) {
            acct.category = 'liabilities';
        }

        this.props.addAccount(this.props.account.user, acct);

        this.setState({
            addDialogOpen: false,
            addingAccountType: null,
            newAccount: Object.assign({}, EMPTY_ACCOUNT),
            dataLoaded: false,
        });
    }

    toggleEdit = (e, account) => {
        e.preventDefault();

        const syncResults = this.state.syncResults;
        if (!this.state.editDialogOpen &&
            syncResults &&
            syncResults[account.id] &&
            syncResults[account.id].error &&
            syncResults[account.id].error.error_code === ITEM_LOGIN_REQUIRED) {
            this.props.getLinkToken(this.props.account.user, account.access_token);
        }

        if (account) {
            account.customNum = account.customNum ?? 1;
            account.customFreq = account.customFreq ?? 'weeks';
        }

        this.setState({
            editDialogOpen: !this.state.editDialogOpen,
            editingAccount: (account) ? account : null,
            mobileUpdateReminders: false,
        });
    }

    editAccount = () => {
        let acct = Object.assign({}, this.state.editingAccount);

        // Need to set reminderStartDate here since no more UI element //
        if (['weeks', 'months'].includes(acct.customFreq)) {
            acct.reminderStartDate = new Date();
        }

        this.props.editAccount(this.props.account.user, acct);

        this.setState({
            editDialogOpen: false,
            editingAccount: null,
            dataLoaded: false,
        });
    }

    deleteAccount = (e, account) => {
        e.preventDefault();

        if (!account.deleteAccount || account.deleteAccount.toLowerCase() !== 'delete') {
            alert('Please type the word "Delete" to delete an account');
            return;
        }

        let state = Object.assign({}, this.state);
        let type = account.category, index = findIndex(state[type], { id: account.id });
        let removed = state[type].splice(index, 1);
        state.totals[type] = numeral(state.totals[type]).value() - numeral(removed[0].value).value();
        state.totals.netWorth = state.totals.assets - state.totals.liabilities;
        this.props.deleteAccount(this.props.account.user, account);

        this.setState({
            assets: state.assets,
            liabilities: state.liabilities,
            totals: state.totals,
            editDialogOpen: false,
            editingAccount: null,
        });
    }

    sortFunc = (a, b, sort, sortDir) => {
        switch(sort) {
            case 'Name':
                a = a.name; b = b.name;
                break;
            case 'Current Value':
                a = Number(a.value); b = Number(b.value);
                break;
            case 'Category':
                a = a.type; b = b.type;
                break;
            case 'Linked/ Manual':
                a = (a.linked) ? 'Linked' : 'Manual';
                b = (b.linked) ? 'Linked' : 'Manual';
                break;
            case 'Last Updated':
                a = a.modified; b = b.modified;
                break;
            default:
                break;
        }

        if (sortDir === 'asc') {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        } else {
            if (a > b) return -1;
            if (a < b) return 1;
            return 0;
        }
    }

    sortAccounts = (field, accounts) => {
        let state = {
            sort: field,
            sortDir: (this.state.sortDir === 'asc') ? 'desc' : 'asc',
        };
        state[accounts] = this.state[accounts].sort((a, b) => this.sortFunc(a, b, state.sort, state.sortDir));
        this.setState(state);
    }

    syncLinkedAccounts = () => {
        this.props.syncLinkedAccounts(this.props.account.user);
        this.setState({ syncing: true, syncResults: null });
    }

    saveHistoricalRecord = () => {
        this.props.saveHistoricalRecord(this.props.account.user, moment().format(DAY_FORMAT), [
            ...this.state.assets,
            ...this.state.liabilities
        ]);
    }

    toggleView = (e, value) => {
        if (value === null) {
            return;
        }

        if (this.state.assets.length === 0 && this.state.liabilities.length === 0 && value === 'Historical') {
            this.setState({
                edit: {
                    error: {
                        body: {
                            msg: 'Please add accounts and click "Save to Historical Record" before accessing this tab.'
                        }
                    }
                }
            });
            return;
        }

        this.setState({ tab: value });

        if (value === 'Historical') {
            this.setState({ historicalLoaded: false });
            this.props.loadHistoricalData(
                this.props.account.user.id,
                this.state.startDate.format(),
                this.state.endDate.format(),
                false,
                this.state.breakdown || 'monthly'
            );
        }
    }

    toggleInfoDialog = () => {
        this.setState({ infoDialogOpen: !this.state.infoDialogOpen });
    }

    toggleReminderInfoDialog = () => {
        this.setState({ reminderInfoDialogOpen: !this.state.reminderInfoDialogOpen });
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    setStartDate = (date) => {
        if (date === null || date === '') {
            return false;
        }

        this.setState({
            dirtyStartDate: moment(date),
            dirtyEndDate: this.state.dirtyEndDate || this.state.endDate,
            dirtyBreakdown: this.state.dirtyBreakdown || this.state.breakdown,
        });
    }

    setEndDate = (date) => {
        if (date === null || date === '') {
            return false;
        }

        this.setState({
            dirtyStartDate: this.state.dirtyStartDate || this.state.startDate,
            dirtyEndDate: moment(date),
            dirtyBreakdown: this.state.dirtyBreakdown || this.state.breakdown,
        });
    }

    setBreakdown = (e) => {
        this.setState({
            dirtyStartDate: this.state.dirtyStartDate || this.state.startDate,
            dirtyEndDate: this.state.dirtyEndDate || this.state.endDate,
            dirtyBreakdown: e.target.value,
        });
    }

    setChartConfig = () => {
        const { dirtyStartDate, dirtyEndDate, dirtyBreakdown, startDate, endDate, breakdown } = this.state;

        let state = {};

        if (dirtyBreakdown === 'daily' && (dirtyEndDate.diff(dirtyStartDate, 'days') + 1) > 31) {
            state.configError = 'Only 31 days of data can be shown at once. Please change your date range and try again.';
        } else if (dirtyBreakdown === 'monthly' && (dirtyEndDate.diff(dirtyStartDate, 'months') + 1) > 24) {
            state.configError = 'Only 24 months of data can be shown at once. Please change your date range and try again.';
        } else if (dirtyBreakdown === 'yearly' && (dirtyEndDate.diff(dirtyStartDate, 'years') + 1) > 20) {
            state.configError = 'Only 20 years of data can be shown at once. Please change your date range and try again.';
        }

        if (!state.configError) {
            this.props.loadHistoricalData(
                this.props.account.user.id,
                dirtyStartDate?.format() || startDate.format(),
                dirtyEndDate?.format() || endDate.format(),
                true,
                dirtyBreakdown || breakdown
            );

            state.historicalLoaded = false;
            state.startDate = dirtyStartDate;
            state.endDate = dirtyEndDate;
            state.breakdown = dirtyBreakdown;
            state.configError = '';
        }

        this.setState(state);
    }

    toggleCategory = (e, category, formattedData) => {
        e.preventDefault();

        let exclude = this.state.excludeAccounts;
        let state = {};

        Object.keys(formattedData.accountsByType[category]).forEach(label => {
            formattedData.accountsByType[category][label].forEach(account => {
                exclude.forEach((name, i) => {
                    if (name === account.name) {
                        exclude.splice(i, 1);
                    }
                });

                if (this.state[`selectAll${category}`]) {
                    exclude.push(account.name);
                }
            });
        });

        state.excludeAccounts = exclude;
        state[`selectAll${category}`] = !this.state[`selectAll${category}`];
        this.setState(state);

        return false;
    }

    toggleAccount = (e, account, formattedData) => {
        e.preventDefault();

        let exclude = this.state.excludeAccounts;
        let state = {};

        // Removing an account from exclude array (aka including) //
        if (exclude.includes(account.name)) {
            exclude.forEach((name, i) => {
                if (name === account.name) {
                    exclude.splice(i, 1);
                }
            });
        } else {
            // Adding an account to exclude array (aka excluding) //
            exclude.push(account.name);
        }

        // If all accounts in a category are set/unset, toggle the state var //
        let accounts = 0, accountsExcluded = 0;
        Object.keys(formattedData.accountsByType[account.category]).forEach(label => {
            formattedData.accountsByType[account.category][label].forEach(account => {
                accounts += 1;
                if (exclude.includes(account.name)) {
                    accountsExcluded += 1;
                }
            });
        });

        state.excludeAccounts = exclude;
        state[`selectAll${account.category}`] = !(accounts === accountsExcluded);
        this.setState(state);

        return false;
    }

    toggleEditHistoricalDialog = (e) => {
        e.preventDefault();

        if (localStorage.getItem('editHistoricalRemember')) {
            window.location.href = '/editHistorical';
        } else {
            this.setState({ editHistoricalDialogOpen: !this.state.editHistoricalDialogOpen });
        }
    }

    editHistoricalRecord = () => {
        if (this.state.editHistoricalRemember) {
            localStorage.setItem('editHistoricalRemember', true);
        }
        window.location.href = '/editHistorical';
    }

    editHistoricalRemember = () => {
        this.setState({ editHistoricalRemember: !this.state.editHistoricalRemember });
    }

    expandMobile = (account) => {
        this.setState({ expandMobile: (this.state.expandMobile?.id === account.id) ? null : account });
    }

    renderAccountRow = (account, i, arr) => {
        const accountType = (arr[0].category === 'assets') ? ASSETS : LIABILITIES;
        const syncResults = this.state.syncResults;
        const syncError = (syncResults && syncResults[account.id] && syncResults[account.id].error);
        const editingAccount = this.state.editingAccount;

        let type = accountType.filter(a => a.value === account.type)[0];
        type = (type) ? type.label : account.type;

        let tooltipText = '';
        if (syncError) {
            tooltipText = syncResults[account.id].error.display_message;
            if (!tooltipText) {
                tooltipText = syncResults[account.id].error.error_message;
            }

        }

        // Figure out if they need an account update reminder //
        let within2Days = accountNeedsUpdateWithin2Days(account),
            pastDueUpdate = accountPastDueUpdate(account);

        // Custom "Last Updated" values //
        let lastUpdated = moment(account.modified).format(DATE_FORMAT_US);
        if (moment().diff(moment(account.modified), 'days') <= 1) {
            lastUpdated = moment(account.modified).format('h:mm a');
        }

        const handleClickRefresh = (e) => {
            e.stopPropagation();

            if (account.linked) {
                this.syncLinkedAccounts();
            } else {
                this.setState({ editingAccount: account });
            }
        };

        let accountValue = numeral(account.value).format(NUM_FORMAT);
        if (editingAccount && editingAccount.id === account.id) {
            accountValue = (
                <NumberFormat
                    autoFocus
                    id="accountValue"
                    type="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    maxLength={17}
                    fixedDecimalScale={true}
                    prefix={'$'}
                    value={editingAccount.value}
                    placeholder={ZERO_NUM}
                    onValueChange={(values) => {
                        let e = { target: {value: values.value} };
                        this.onChangeAccount(e, 'value', true)
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.editAccount();
                        }
                    }}
                />
            );
        }

        return (
            <div className="account-row-wrapper" key={i}>
                <div className="account-row" onClick={() => this.expandMobile(account)}>
                    <div className="body-col">{account.name}</div>
                    <div className="body-col num">{accountValue}</div>
                    <div className="body-col">{type}</div>
                    <div className="body-col">{(account.linked) ? 'Linked' : 'Manual'}</div>
                    <div className="body-col">
                        <span className={cn({pending: within2Days, overdue: pastDueUpdate})}>{lastUpdated}</span>
                        <div className="icons">
                            {(within2Days || pastDueUpdate) &&
                                <img src={RefreshIcon} alt="Refresh icon, green, circular arrow" onClick={handleClickRefresh} />
                            }
                            {(syncError) &&
                                <TooltipMUI title={tooltipText} arrow placement="right">
                                    <img src={ErrorIcon} alt="Error icon, red, exclamation mark" />
                                </TooltipMUI>
                            }
                        </div>
                        <a href="noop" onClick={(e) => this.toggleEdit(e, account)}>Edit</a>
                    </div>
                </div>
                {this.state.expandMobile?.id === account.id && this.state.mobileLegend &&
                    <div className="expand-mobile">
                        <div className="expand-row">
                            <label>Category</label>
                            <span>{type}</span>
                        </div>
                        <div className="expand-row">
                            <label>Linked/Manual</label>
                            <span>{(account.linked) ? 'Linked' : 'Manual'}</span>
                        </div>
                        <div className="expand-row">
                            <label>Last Updated</label>
                            <span className={cn({pending: within2Days, overdue: pastDueUpdate})}>{lastUpdated}</span>
                        </div>
                        <a href="noop" onClick={(e) => this.toggleEdit(e, account)}>Edit</a>
                    </div>
                }
            </div>
        );
    };

    adcBackLink = (e, isEdit) => {
        e.preventDefault();

        if (this.state.mobileUpdateReminders) {
            this.setState({ mobileUpdateReminders: false });
        } else if (isEdit) {
            this.toggleEdit(e);
        } else {
            this.setAccountType(e, null);
        }

        return false;
    }

    renderAccountDialogContent = (account, isEdit) => {
        const { linkToken } = this.props;
        const { syncResults } = this.state;

        if (account == null) {
            return false;
        }

        let editingCategory = ASSETS;
        if (account && account.category === 'liabilities') {
            editingCategory = LIABILITIES;
        }
        let linkedAccountType = 'Other';
        if (account?.linked) {
            const acct = editingCategory.filter(a => a.value === account.type);
            if (acct?.length) {
                linkedAccountType = acct[0].label;
            }
        }

        return (
            <div className="account-dialog-content" key={account.id}>
                <div className="adc-wrapper">
                    <BackLink onClick={(e) => this.adcBackLink(e, isEdit)} />

                    <form id="metadata" className={cn(this.state.mobileUpdateReminders && 'closed')}>
                        <fieldset>
                            <h2 htmlFor="accountName">Account Name</h2>
                            <input
                                type="text"
                                id="accountName"
                                value={account.name}
                                onChange={(e) => this.onChangeAccount(e, 'name', isEdit)}
                                placeholder={'Name your account'} />
                        </fieldset>

                        {account.linked ?
                            <fieldset>
                                <h2 htmlFor="accountType">Account Type</h2>
                                <input type="text" value={linkedAccountType} disabled />
                            </fieldset>

                            :

                            <fieldset>
                                <h2 htmlFor="accountType">Account Type</h2>
                                <select id="accountType" value={account.type} onChange={(e) => this.onChangeAccount(e, 'type', isEdit)}>
                                    <option value="">Select</option>
                                    {(account.category === 'assets' || !isEdit) &&
                                        <optgroup label={'Assets'}>
                                            {ASSETS.filter(a => a.type === 'manual').map((a, i) => <option value={a.value} key={i}>{a.label}</option>)}
                                        </optgroup>
                                    }
                                    {(account.category === 'liabilities' || !isEdit) &&
                                        <optgroup label={'Liabilities'}>
                                            {LIABILITIES.filter(l => l.type === 'manual').map((l, i) => <option value={l.value} key={i}>{l.label}</option>)}
                                        </optgroup>
                                    }
                                </select>
                            </fieldset>
                        }

                        {account.linked ?
                            <fieldset>
                                <h2 htmlFor="accountValue">Account Value</h2>
                                <input type="text" value={numeral(account.value).format(NUM_FORMAT)} disabled />
                            </fieldset>

                            :

                            <fieldset>
                                <h2 htmlFor="accountValue">Account Value</h2>
                                <NumberFormat
                                    id="accountValue"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    maxLength={17}
                                    fixedDecimalScale={true}
                                    prefix={'$'}
                                    value={account.value}
                                    placeholder={ZERO_NUM}
                                    onValueChange={(values) => {
                                        let e = { target: {value: values.value} };
                                        this.onChangeAccount(e, 'value', isEdit)
                                    }}
                                />
                            </fieldset>
                        }

                        {account.linked && linkToken != null && syncResults != null && syncResults[account.id].error?.error_code === ITEM_LOGIN_REQUIRED &&
                            <fieldset>
                                <h2>Login Details</h2>
                                <PlaidLink token={linkToken} onSuccess={this.onLinkSuccess} className="secondary">
                                    Fix Login Details
                                </PlaidLink>
                            </fieldset>
                        }
                    </form>

                    <form id="reminderFrequency" className={cn(this.state.mobileUpdateReminders && 'open')}>
                        <fieldset>
                            <h2>
                                Update Reminder Frequency
                                <IconButton type="info" content="?" tooltip="How often do you want to update this account's balance? Accounts ready for updating will show an icon in the 'Last Updated' column." />
                            </h2>
                        </fieldset>

                        <fieldset className="custom-range">
                            <h2>Every:</h2>
                            <select id="customNum" value={account.customNum} onChange={(e) => this.onChangeAccount(e, 'customNum', isEdit)}>
                                {[...Array(12).keys()].map(i => {
                                    i++;
                                    return (<option value={i} key={i}>{i}</option>);
                                })}
                            </select>
                            <select id="customFreq" value={account.customFreq} onChange={(e) => this.onChangeAccount(e, 'customFreq', isEdit)}>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                            </select>
                        </fieldset>

                        <fieldset>
                            <div className="radio-group">
                                <input type="radio"
                                    id="onDay"
                                    name="reminderType"
                                    value="On Day"
                                    checked={account.reminderType === 'On Day'}
                                    onChange={(e) => this.onChangeAccount(e, 'reminderType', isEdit)} />
                                <label className="radio" htmlFor="onDay">On Day</label>
                                <input type="radio"
                                    id="lastUpdate"
                                    name="reminderType"
                                    value="From Last Update"
                                    checked={account.reminderType === 'From Last Update'}
                                    onChange={(e) => this.onChangeAccount(e, 'reminderType', isEdit)} />
                                <label className="radio" htmlFor="lastUpdate">From Last Update</label>
                            </div>
                        </fieldset>

                        {account.customFreq === 'weeks' && account.reminderType === 'On Day' &&
                            <fieldset>
                                <h2>On these days:</h2>
                                <ul className="weekdays">
                                    {WEEKDAYS.map((d, i) => {
                                        return (
                                            <li key={i}>
                                                <Checkbox
                                                    id={d.id}
                                                    checked={account.weekdays?.includes(d.full)}
                                                    onChange={(e) => this.toggleWeekday(e, d, isEdit)}
                                                />
                                                <label htmlFor={d.id}>{d.single}</label>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </fieldset>
                        }

                        {account.customFreq === 'months' && account.reminderType === 'On Day' &&
                            <fieldset>
                                <h2 className="alt" htmlFor="monthDay">On day:</h2>
                                <select id="monthDay" value={account.monthDay} onChange={(e) => this.onChangeAccount(e, 'monthDay', isEdit)}>
                                    <option value=""></option>
                                    {[...Array(28).keys()].map(i => {
                                        i++;
                                        return (<option value={i} key={i}>{i}</option>);
                                    })}
                                    <option value="last">Last</option>
                                </select>
                            </fieldset>
                        }
                    </form>

                    {account.linked && linkToken === null && syncResults != null && syncResults[account.id] && syncResults[account.id].error?.error_code === ITEM_LOGIN_REQUIRED &&
                        <Loading />
                    }

                    <a href="noop" className={cn('update-reminders', this.state.mobileUpdateReminders && 'hide')} onClick={this.toggleUpdateReminders}>Update Reminder Frequency ></a>

                    <button className="primary" onClick={(isEdit) ? this.editAccount : this.addAccount}>Save</button>
                </div>

                {isEdit &&
                    <div className="delete-account">
                        <h2>Permanently Delete</h2>
                        <p>WARNING: This will immediately delete the account and all its associated data. This cannot be undone.</p>
                        <div className="action">
                            <input
                                type="text"
                                placeholder={'Type "delete"'}
                                value={account.deleteAccount}
                                onChange={(e) => this.onChangeAccount(e, 'deleteAccount', isEdit)}
                            />
                            <button className={cn('delete', account?.deleteAccount?.toLowerCase() === 'delete' && 'active')} onClick={(e) => this.deleteAccount(e, account)}>Delete</button>
                        </div>
                    </div>
                }
            </div>
        );
    };

    renderAccountLegend = (account, j, formattedData) => {
        const checked = !this.state.excludeAccounts.includes(account.name);

        return (
            <li key={j}>
                <Checkbox
                    id={account.name}
                    checked={checked}
                    onClick={(e) => this.toggleAccount(e, account, formattedData)}
                />
                <label htmlFor={account.name}>{account.name}</label>
            </li>
        );
    };

    chartMouseOut = () => {
        this.setState({ barHover: {} });
    };

    chartMouseMove = (opts) => {
        const payload = (opts.activePayload) ? opts.activePayload[0].payload : {};
        const barHover = { ...payload, index: opts.activeTooltipIndex };

        if (barHover.index !== this.state.barHover.index) {
            this.setState({ barHover: barHover });
        }
    };

    formatXAxis = (tick, isDefault) => {
        switch(this.state.breakdown) {
            case 'daily':
                return moment(tick).format('D');
            case 'monthly':
                return moment(tick, DATE_FORMAT_SHORT).format('MMMM');
            case 'yearly':
                return moment(tick, DATE_FORMAT_SHORT).format('YYYY');
            default:
                return moment(tick, DATE_FORMAT_SHORT).format('MMMM');
        }
    }

    formatLegendDate = (date) => {
        switch(this.state.breakdown) {
            case 'daily':
                return moment(date).format('MMMM DD, YYYY')
            case 'yearly':
                return moment(date).format('YYYY')
            case 'monthly':
            default:
                return moment(date).format('MMMM YYYY')
        }
    }

    toggleAccountsMobile = (e) => {
        e.preventDefault();
        this.setState({ mobileLegendOpen: !this.state.mobileLegendOpen });
        return false;
    }

    toggleUpdateReminders = (e) => {
        e.preventDefault();
        this.setState({ mobileUpdateReminders: !this.state.mobileUpdateReminders });
        return false;
    }

    render() {
        const { loaded, dataLoaded, syncing, tab, sort, sortDir, assets, liabilities, totals, edit, selectAllassets, selectAllliabilities } = this.state;
        const { historicalLoaded, startDate, dirtyStartDate, endDate, dirtyEndDate, breakdown, dirtyBreakdown, configError, excludeAccounts, newAccount, editingAccount, editHistoricalRemember, barHover } = this.state;
        const { account, financialPriorities, lastUpdated, linkToken, historical, classes } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded)) {
            return (<Loading />);
        } else {
            let Nav = (account.user) ? <NavBarLoggedIn account={account} fp={financialPriorities} /> : <NavBar />;

            let saveText = (account.user) ? 'Save to Historical Record' : 'Save Accounts';
            let subtext = (account.user) ?
                `Save account data for today, ${moment().format(DATE_FORMAT_US)}.` :
                "You'll create a login to save your accounts and data.";

            let formattedData = null, legendPayload = null;
            if (historicalLoaded) {
                formattedData = formatData(historical, startDate.format(DAY_FORMAT), endDate.format(DAY_FORMAT), breakdown, excludeAccounts);

                const legendData = (Object.keys(barHover).length) ? barHover : formattedData.data[formattedData.data.length-1];
                legendPayload = [{ label: null, value: this.formatLegendDate(legendData?.date), type: 'date', color: 'white' }];
                Object.keys(formattedData.legend).forEach((l, i) => {
                    legendPayload.push({label: l, value: numeral((!legendData) ? 0 : legendData[l]).format(NUM_FORMAT_SHORT), type: 'circle', color: COLORS[l]});
                });
                legendPayload.push({label: 'Net Worth', value: numeral((!legendData) ? 0 : legendData['Net Worth']).format(NUM_FORMAT_SHORT), type: 'square', color: '#66D8DF'});
            }

            let alert = null;
            if (edit.success) {
                alert = { severity: 'success', msg: edit.success.msg };
            } else if (edit && edit.error && edit.error.body) {
                alert = { severity: 'error', msg: edit.error.body.msg };
            }

            return (
                <>

                {syncing &&
                    <div className="syncing">
                        <div className="status">
                            <img src={RefreshIcon} alt="Refresh icon, green, circular arrow" />
                            <h1>Syncing Linked Accounts</h1>
                        </div>
                    </div>
                }

                <div className="wrapper">
                    {Nav}

                    <div className="main-bg" />

                    <div className="main assets-liabilities">
                        {account.user &&
                            <ToggleButtonGroup value={tab} exclusive onChange={this.toggleView}>
                                <ToggleButton value="Accounts">Accounts</ToggleButton>
                                <ToggleButton value="Historical">Historical</ToggleButton>
                            </ToggleButtonGroup>
                        }

                        <h1>Assets & Liabilities</h1>
                        {lastUpdated !== '' && <h2 className="sub light">Last Updated {moment(lastUpdated).format(DATE_FORMAT)}</h2>}

                        {tab === 'Accounts' && <>
                            <div className="actions">
                                <div className="sync-accounts">
                                    <button className={cn('primary', (syncing) ? 'secondary' : undefined)} onClick={this.syncLinkedAccounts} disabled={syncing}>
                                        {(syncing) ? 'Syncing...' : 'Sync Linked Accounts'}
                                    </button>
                                </div>

                                <div className="save-accounts">
                                    <button className="secondary" onClick={this.saveHistoricalRecord}>{saveText}</button>
                                    <IconButton type="info" content="?" onClick={this.toggleInfoDialog} />
                                    <p>{subtext}</p>
                                </div>

                                <div className="net-worth">
                                    <div className="nw-wrapper">
                                        Your Net Worth:&nbsp;
                                        <span className={cn(totals.netWorth > 0 ? 'positive' : undefined)}>
                                            <CountUpAnimation>{numeral(totals.netWorth).format(NUM_FORMAT)}</CountUpAnimation>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="account-table">
                                <img src={AssetsIcon} alt="Assets icon, sunrise, teal green" />
                                <h1>Assets</h1>

                                <div className="header">
                                    {HEADER_FIELDS.map((field, i) => {
                                        const icon = (sortDir === 'desc') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
                                        return (
                                            <div className={cn('header-col', sort === field ? 'selected' : undefined)} key={i} onClick={() => this.sortAccounts(field, 'assets')}>
                                                <label>{field}</label>
                                                {sort === field && icon}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="body">
                                    {assets.map(this.renderAccountRow)}
                                </div>

                                <div className="add-account" onClick={this.toggleAdd}>
                                    <label>Add Account</label>
                                    <img src={AddIcon} alt="Add icon, plus sign in circle, green" />
                                </div>

                                <div className="total assets">
                                    <label>Total Assets: </label>
                                    <span>{numeral(totals.assets).format(NUM_FORMAT)}</span>
                                </div>
                            </div>

                            <div className="account-table">
                                <img src={LiabilitiesIcon} alt="Liabilities icon, sunset, orange" />
                                <h1>Liabilities</h1>

                                <div className="header">
                                    {HEADER_FIELDS.map((field, i) => {
                                        const icon = (sortDir === 'desc') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
                                        return (
                                            <div className={cn('header-col', sort === field ? 'selected' : undefined)} key={i} onClick={() => this.sortAccounts(field, 'liabilities')}>
                                                <label>{field}</label>
                                                {sort === field && icon}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="body">
                                    {liabilities.map(this.renderAccountRow)}
                                </div>

                                <div className="add-account" onClick={this.toggleAdd}>
                                    <label>Add Account</label>
                                    <img src={AddIcon} alt="Add icon, plus sign in circle, green" />
                                </div>

                                <div className="total liabilities">
                                    <label>Total Liabilities: </label>
                                    <span>{numeral(totals.liabilities).format(NUM_FORMAT)}</span>
                                </div>
                            </div>

                            <div className="actions">
                                <div className="save-accounts lower">
                                    <button className="secondary" onClick={this.saveHistoricalRecord}>{saveText}</button>
                                    <IconButton type="info" content="?" onClick={this.toggleInfoDialog} />
                                    <p>{subtext}</p>
                                </div>
                            </div>
                        </>}

                        {tab === 'Historical' && <>
                            {!historicalLoaded &&
                                <div className="historical-loading">
                                    <Loading />
                                </div>
                            }

                            {historicalLoaded && <>
                                <div className="actions">
                                    <div className="edit-historical-record">
                                        <button className="primary" onClick={this.toggleEditHistoricalDialog}>Edit Historical Record</button>
                                    </div>
                                </div>

                                <div className="chart-wrapper">
                                    <div className="legend">
                                        <div className="legend-header">
                                            <h1>Assets</h1>
                                            <a href="noop" className="select-clear-all" onClick={(e) => this.toggleCategory(e, 'assets', formattedData)}>
                                                {(selectAllassets) ? 'Clear' : 'Select All'}
                                            </a>
                                        </div>

                                        <div className="legend-category assets">
                                            {Object.keys(formattedData.accountsByType.assets).map((l, i) => {
                                                return (
                                                    <div className="legend-label" key={i}>
                                                        <h2>{l}</h2>
                                                        <ul>
                                                            {formattedData.accountsByType.assets[l].map((account, j) => {
                                                                return this.renderAccountLegend(account, j, formattedData);
                                                            })}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="legend-header">
                                            <h1>Liabilities</h1>
                                            <a href="noop" className="select-clear-all" onClick={(e) => this.toggleCategory(e, 'liabilities', formattedData)}>
                                                {(selectAllliabilities) ? 'Clear' : 'Select All'}
                                            </a>
                                        </div>

                                        <div className="legend-category liabilities">
                                            {Object.keys(formattedData.accountsByType.liabilities).map((l, i) => {
                                                return (
                                                    <div className="legend-label" key={i}>
                                                        <h2>{l}</h2>
                                                        <ul>
                                                            {formattedData.accountsByType.liabilities[l].map((account, j) => {
                                                                return this.renderAccountLegend(account, j, formattedData);
                                                            })}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="chart-wrapper-2">
                                        <div className="chart-options">
                                            <div className="mobile-band" />
                                            <div className="option">
                                                <label>Start</label>
                                                <DatePicker className="datepicker" selected={dirtyStartDate?.toDate() || startDate.toDate()} maxDate={dirtyEndDate?.toDate() || endDate.toDate()} onChange={this.setStartDate} />
                                            </div>
                                            <div className="option">
                                                <label>End</label>
                                                <DatePicker className="datepicker" selected={dirtyEndDate?.toDate() || endDate.toDate()} maxDate={moment().toDate()} onChange={this.setEndDate} />
                                            </div>
                                            <div className="option">
                                                <label>View By</label>
                                                <select value={dirtyBreakdown || breakdown} onChange={this.setBreakdown}>
                                                    {Object.keys(BREAKDOWNS).map((k, i) =>
                                                        <option key={i} value={BREAKDOWNS[k].breakdown}>{BREAKDOWNS[k].menuLabel}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="option">
                                                <button className="primary view" onClick={this.setChartConfig}>View</button>
                                            </div>
                                            <a href="noop" className="select-accounts" onClick={this.toggleAccountsMobile}>Select Accounts ></a>
                                        </div>

                                        {configError !== '' && <div className="config-error">{configError}</div>}

                                        <div className="chart-container">
                                            <div className="historical-chart">
                                                {formattedData.data.length > 0 && <>
                                                    <ResponsiveContainer width="100%" height="80%">
                                                        <ComposedChart data={formattedData.data} margin={{ top: 40, right: 30, bottom: 0, left: 40, }} onMouseMove={this.chartMouseMove} onMouseLeave={this.chartMouseOut}>
                                                            <Tooltip contentStyle={{display: 'none'}} cursor={{ stroke: '#F4F6F8', strokeWidth: `${(MAGIC_NUMERATOR / formattedData.data.length)}%` }} />
                                                            <CartesianGrid stroke="#E6E6E6" vertical={false} />
                                                            <XAxis dataKey="date" minTickGap={0} tickFormatter={this.formatXAxis} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                                            <YAxis tickFormatter={t => numeral(t).format(NUM_FORMAT_SHORT)} tickCount={6} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                                            <Bar dataKey={NET_WORTH} fill="#B9F1F4" />
                                                            {Object.keys(formattedData.legend).map((l, i) =>
                                                                <Line type="monotone"
                                                                    dataKey={l}
                                                                    key={i}
                                                                    stroke={COLORS[l]}
                                                                    strokeWidth={2}
                                                                    dot={{fill: COLORS[l], stroke: COLORS[l], r: 7}} />
                                                            )}
                                                        </ComposedChart>
                                                    </ResponsiveContainer>

                                                    <div className="historical-legend">
                                                        <ul>
                                                            {legendPayload.map((lp, i) => {
                                                                return (
                                                                    <li cn={(lp.type === 'date') ? 'date' : undefined} key={i}>
                                                                        {lp.type !== 'date' && <div className={`icon ${lp.type}`} style={{ backgroundColor: lp.color }} />}
                                                                        {lp.label && <label style={{ color: lp.color }}>{lp.label}</label>} &nbsp;
                                                                        <span>{lp.value}</span>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </>}
                                                {formattedData.data.length === 0 &&
                                                    <div className="no-data">
                                                        There is no historical data for the specified start and end dates. Please check your inputs and try again.
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="actions">
                                    <div className="edit-historical-record lower">
                                        <button className="primary" onClick={this.toggleEditHistoricalDialog}>Edit Historical Record</button>
                                    </div>
                                </div>
                            </>}
                        </>}
                    </div>

                    {historicalLoaded &&
                        <Dialog onClose={this.toggleAccountsMobile} open={this.state.mobileLegendOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleAccountsMobile} />
                            <div className={cn('legend mobile-legend')}>
                                <div className="legend-header">
                                    <h1>Assets</h1>
                                    <a href="noop" className="select-clear-all" onClick={(e) => this.toggleCategory(e, 'assets', formattedData)}>
                                        {(selectAllassets) ? 'Clear' : 'Select All'}
                                    </a>
                                </div>

                                <div className="legend-category assets">
                                    {Object.keys(formattedData.accountsByType.assets).map((l, i) => {
                                        return (
                                            <div className="legend-label" key={i}>
                                                <h2>{l}</h2>
                                                <ul>
                                                    {formattedData.accountsByType.assets[l].map((account, j) => {
                                                        return this.renderAccountLegend(account, j, formattedData);
                                                    })}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="legend-header">
                                    <h1>Liabilities</h1>
                                    <a href="noop" className="select-clear-all" onClick={(e) => this.toggleCategory(e, 'liabilities', formattedData)}>
                                        {(selectAllliabilities) ? 'Clear' : 'Select All'}
                                    </a>
                                </div>

                                <div className="legend-category liabilities">
                                    {Object.keys(formattedData.accountsByType.liabilities).map((l, i) => {
                                        return (
                                            <div className="legend-label" key={i}>
                                                <h2>{l}</h2>
                                                <ul>
                                                    {formattedData.accountsByType.liabilities[l].map((account, j) => {
                                                        return this.renderAccountLegend(account, j, formattedData);
                                                    })}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Dialog>
                    }

                    <Dialog onClose={this.toggleInfoDialog} open={this.state.infoDialogOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleInfoDialog} />
                        <p>Account values are saved to your historical record automatically once daily. But you
                        can save your current account values to your Historical Record now by clicking 'Save.'</p>
                        <button className="primary" onClick={this.toggleInfoDialog}>Ok</button>
                    </Dialog>

                    <Dialog onClose={this.toggleReminderInfoDialog} open={this.state.reminderInfoDialogOpen} className={classes.Dialog}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleReminderInfoDialog} />
                        <p>How often do you want to update this account's balance? Accounts ready for updating will show an icon in the 'Last Updated' column.</p>
                        <button className="primary" onClick={this.toggleReminderInfoDialog}>Ok</button>
                    </Dialog>

                    <Dialog onClose={this.toggleAdd} open={this.state.addDialogOpen} className={classes.Dialog} disableEnforceFocus={true}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleAdd} />

                        {!this.state.addingAccountType && <>
                            <h2>Let's add an account!</h2>
                            <h1>What type of account would you like to add?</h1>

                            <div className="account-types">
                                <div className="type">
                                    <button className="primary" onClick={(e) => this.setAccountType(e, 'manual')}>Manual Account</button>
                                    <p><b>Manual accounts</b> are any asset or liability that can't be synced
                                    online.</p><p>These include:</p>
                                    <ul>
                                        <li>Real Estate</li>
                                        <li>Valuables</li>
                                        <li>Debts to Family or Friends, etc.</li>
                                    </ul>
                                </div>
                                <div className="type">
                                    {linkToken != null &&
                                        <PlaidLink className="primary" token={linkToken} onSuccess={this.onLinkSuccess}>
                                            Linked Account
                                        </PlaidLink>
                                    }
                                    {linkToken === null && <Loading />}
                                    <p><b>Linked accounts</b> can be synced online.</p><p>These include:</p>
                                    <ul>
                                        <li>Checking & Savings</li>
                                        <li>Investment & Retirement</li>
                                        <li>Credit Card</li>
                                        <li>Mortgage Loan, etc.</li>
                                    </ul>
                                </div>
                            </div>
                        </>}

                        {this.state.addingAccountType === 'manual' && <>
                            <h2>Let's add an account!</h2>
                            <h1>Add your manual account</h1>
                            {this.renderAccountDialogContent(newAccount, false)}
                        </>}
                    </Dialog>

                    <Dialog onClose={this.toggleEdit} open={this.state.editDialogOpen && this.state.editingAccount} className={classes.Dialog} disableEnforceFocus={true}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleEdit} />
                        <h1 className="solo">{`${editingAccount?.linked ? 'Linked' : 'Manual'} Account Details`}</h1>
                        {this.renderAccountDialogContent(editingAccount, true)}
                    </Dialog>

                    <Dialog onClose={this.toggleEditHistoricalDialog} open={this.state.editHistoricalDialogOpen} className={classes.Dialog} disableEnforceFocus={true}>
                        <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={this.toggleEditHistoricalDialog} />
                        <h1 className="solo">Are you sure you want to edit your historical record?</h1>
                        <div className="edit-historical-actions">
                            <button className="primary" onClick={this.editHistoricalRecord}>Yes</button>
                            <button className="secondary" onClick={this.toggleEditHistoricalDialog}>No</button>
                        </div>
                        <div className="remember">
                            <Checkbox id="rememberCheckbox" onChange={this.editHistoricalRemember} checked={editHistoricalRemember} />
                            <label htmlFor="rememberCheckbox">Don't ask this again</label>
                        </div>
                    </Dialog>

                    {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                    <Footer />
                </div>

            </>
            );
        }
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(AssetsLiabilities);
